import React, {useEffect, useState} from 'react';
import {FullTaskResponse, Task, TaskType} from "../../entities/task";
import {useNavigate} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import GeneralTaskInfo from "./GeneralTaskInfo/GeneralTaskInfo";
import {Operation} from "../../entities/operation";
import {instance} from "../../api/config";
import axios, {AxiosError} from "axios";
import {toast} from 'react-hot-toast'
import ChildTasksInfo from "./ChildTasksInfo/ChildTasksInfo";
import {useAuth} from "../../context/AuthProvider";
import {DetailType} from "../../entities/detail_type";
import LogisticsTaskInfo from './LogisticsTaskInfo/LogisticsTaskInfo';
import { Button } from '../Button/Button';
import { FieldMain } from '../Field/Fields';
import './Task.css'
import { EditableText } from '../editableText/editableText';
import { text } from 'stream/consumers';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';

interface TaskProps {
     
    task: FullTaskResponse,
}

const TaskComponent: React.FC<TaskProps> = (props: TaskProps) => {

    const { user } = useAuth()

    const navigate = useNavigate()

    const [note, setDescription] = useState(props.task?.note);
    const [comment, setComment] = useState(props.task?.comment);

    const [progress, setProgress] = useState<number>(props.task.task.progress);
    const [operations, setOperation] = useState<Operation[]>(props.task?.operations || []);
    const [isDelete, setIsDelete] = useState(false);
    const [status, setStatus] = React.useState<boolean>(false);
    const [isFirstDiv, setIsFirstDiv] = useState(true);
    const [done, setDone] = useState(0);
    const [qualified, setQualified] = useState(0);
    const [storageAdress, setStorageAdress] = useState("")
    const handleClick = () => {
      setIsFirstDiv(!isFirstDiv);
    }

    const handleSaveButtonClick = async () => {

        const PatchRequestData = {
            "ID": props.task.ID,
            "operations": operations,
            "progress": progress,
            "note": note,
            "comment": comment,
        }
        try {
            const resp = instance.patch("/api/tasks", PatchRequestData)

        } catch (err: AxiosError | any) {
            console.error(err)
        }
        toast("Задача успешно сохранена!")
    }

    const handleDeleteButtonClick = async (e: React.FormEvent) => {
        e.preventDefault()
        setIsDelete(true)
    }

    const handleClose = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsDelete(false)
    }

    const handleOPFinish = async (e: React.FormEvent) => {
        e.preventDefault()
        if (props.task.quantity != props.task.done && props.task.quantity != done) {
            toast("Нельзя завершить задачу, пока прогресс не равен 100%")
            return
        }
        const resp = await instance.patch("/api/tasks", {
            ID: props.task.ID,
            type: props.task.task.type,
            status: true
            })
        toast("Задача завершена")
        navigate("/tasks")
    }

    const handlePurchaseFinish = async (e: React.FormEvent) => {
        const resp = await instance.patch("/api/tasks", {
            ID: props.task.ID,
            type: props.task.task.type,
            status: true
            })
        toast("Задача завершена")
        navigate("/tasks")
    }

    const handleGeneralFinish = async (e: React.FormEvent) => {
        const resp = await instance.patch("/api/tasks", {
            ID: props.task.ID,
            status: true,
            type: props.task.task.type,
            })
        toast("Задача завершена")
        navigate("/tasks")
    }

    const handleStorageFinish = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!storageAdress) {
            toast("Нельзя завершить задачу, не указан адрес на складе")
            return
        }
        const resp = await instance.patch("/api/tasks", {
            ID: props.task.ID,
            status: true,
            address: storageAdress,
            type: props.task.task.type,
            prev_task_id: props.task.task.prev_task_id
            })
        toast("Задача завершена")
        navigate("/tasks")
    }

    const handleQCFinish = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!props.task.qualified && !qualified) {
            toast("Нельзя завершить задачу, не указано количество проверенных деталей")
            return
        }
        const resp = await instance.patch("/api/tasks", {
            ID: props.task.ID,
            status: true,
            type: props.task.task.type,
            })
        toast("Задача завершена")
        navigate("/tasks")
    }
    const handleDelete = async (e: React.FormEvent) => {
        e.preventDefault();
        const resp = instance.delete("/api/tasks", { data: {
            "ID": props.task.ID,
            }})
        navigate("/tasks")
        toast("Задача успешно удалена!")
    
}

    
    return (
        <div className="sub-button-field-param">
            <div className="top-buttons-container">
                <Button buttonType='white-shadowed' onClick={() => {
                    navigate(-1)
                }}>Вернуться назад
                </Button>
                <div className="title-container">
                    <h2>{props.task.task.name}</h2>
                    <h3>Дата получения
                        НЗИ: {props.task.task.finish_date.slice(0, 10)}</h3>
                    <h3>{props.task.detail?.number}</h3>
                </div>              
                <div style={{ display: "flex", justifyContent: "end"}}>  
                    {(props.task.task.type === TaskType.General) ?
                        <Button buttonType='white-shadowed'
                                style={{ marginRight: "10px"}} 
                                onClick={handleGeneralFinish}>Завершить
                        </Button> : <></>
                    }
                    <Button buttonType='white-shadowed'
                            style={{ marginRight: "10px" }} 
                            onClick={handleSaveButtonClick}>Сохранить
                    </Button>
                    {(user?.role === 1)?
                        <Button className="task-default-button-del"
                                onClick={handleDeleteButtonClick}>
                                <div className='img-flex'>
                                    <img src="/trash.png" alt='trash' width='20' height='20' style={{display: 'flex', marginRight: '5px'}}></img>
                                    <label> Удалить </label>
                                </div>
                                    
                        </Button> : <></>
                    }
                </div>
            </div>
                <GeneralTaskInfo
                executor={props.task.task.executor}
                project={props.task.task.project}
                responsible={props.task.task.responsible}
                desciption={props.task.task.description}
            />
            <div className='secondary-info-container'>
                {(props.task.task.type === TaskType.Manufacturing) ?
                            <FieldMain className='special-info-item'>
                                <div className='man-info'><h3>Деталь:&#160; </h3><h4>{props.task.detail_type?.name}</h4></div>
                                <div className='man-info'><h3>Обозначение:&#160;</h3><h4>{props.task.detail_type?.number}</h4></div>
                                <div className='man-info'><h3>Количество:&#160;</h3><h4>{props.task.quantity}</h4></div>
                                <div className='man-info'><h3>Материал:&#160; </h3><h4>{props.task.material}</h4></div>
                                <div className='man-info'><h3>Размеры:&#160; </h3><h4>{props.task.size}</h4></div>
                            </FieldMain> : <></>
                }
                {
                    (props.task.task.type === TaskType.Purchase) ?
                        <FieldMain className="special-info-item">
                            {isFirstDiv ? "Общая информация" : "Полная информация"}
                            <div className='special-info-item-pursage'>
                                <div onClick={handleClick}> 
                                    {isFirstDiv ? (
                                        <div style={{ display: "flex", justifyContent: "space-between", alignContent: "start"}}>
                                            <div>
                                                <div className='man-info'><h3>Материал:&#160;</h3><h4> {props.task.material}</h4></div>
                                                <div className='man-info'><h3>Вес партии: &#160;</h3><h4>{props.task.supply_weight}</h4></div>
                                                <div className='man-info'><h3>Ожидаемая дата поставки: &#160;</h3><h4>{props.task.predicted_supply_date?.slice(0, 10)}</h4></div>
                                            </div>
                                            <div>
                                                <div className='man-info'><h2>˙˙˙</h2></div>
                                            </div>
                                        </div>
                                            
                                    ) : (
                                        <div>
                                            <div className='man-info'><h3>Материал:&#160;</h3><h4> {props.task.material}</h4></div>
                                            <div className='man-info'><h3>Гост:&#160;</h3><h4> {props.task.gost}</h4></div>
                                            <div className='man-info'><h3>Размер: &#160;</h3><h4>{props.task.size}</h4></div>
                                            <div className='man-info'><h3>Запланированная цена, рубли: &#160;</h3><h4>{props.task.predicted_price}</h4></div>
                                            <div className='man-info'><h3>Входит в бюджет: &#160;</h3><h4>{props.task.is_in_budget}</h4></div>
                                            <div className='man-info'><h3>Поставщик:&#160;</h3><h4> {props.task.supplier}</h4></div>
                                            <div className='man-info'><h3>Вес партии, кг: &#160;</h3><h4>{props.task.supply_weight}</h4></div>
                                            <div className='man-info'><h3>Срок поставки после оплаты счета, дни: &#160;</h3><h4>{props.task.supply_time_after_payment_from} - {props.task.supply_time_after_payment_to}</h4></div>
                                            <div className='man-info'><h3>Ожидаемая дата поставки:&#160; </h3><h4>{props.task.predicted_supply_date?.slice(0, 10)}</h4></div>
                                            <div className='man-info'><h3>Планируемая дата поставки:&#160; </h3><h4>{props.task.planned_supply_date?.slice(0, 10)}</h4></div>
                                            
                                        </div>
                                        
                                    )}
                                </div>
                                
                                <Button buttonType='white-shadowed' className="back-button end-button" onClick={handlePurchaseFinish}>Завершить
                                    задачу
                                </Button>
                            </div>
                        </FieldMain> : <></>
                }
                {
                    (props.task.task.type === TaskType.Operation) ?
                        <FieldMain className="special-info-item">
                            <div>
                                <h3>Тип операции: {props.task.operation?.type}</h3>
                                <h3>Рабочее место: {props.task.work_place?.name}</h3>
                                <h3>Адрес: {props.task.work_place?.address}</h3>
                                <h3>Количество деталей: {props.task.quantity}</h3>
                                <h3>Количество обработанных деталей: </h3>
                                <InputNumber value={done} onValueChange={(e: InputNumberValueChangeEvent) => setDone(e.value || 0)} mode="decimal" showButtons min={0} max={props.task.quantity} />
                                <Button buttonType='white-shadowed' className="back-button end-button" onClick={handleOPFinish}>Завершить
                                    задачу
                                </Button>
                            </div>
                        </FieldMain> : <></>
                }
                {
                    (props.task.task.type === TaskType.Storing) ?
                        <FieldMain className="special-info-item">
                            <div className="special-info-container-item">
                                    <h3>Необходимо положить
                                        детали{props.task.task.name.split(":")[1]} на склад</h3>
                                
                                <div>
                                    <p className="sub-end-task">Чтобы завершить задачу, введите адрес на складе</p>
                                    <Form.Control aria-label="Finish Date"
                                                id="2"
                                                className="form-control task-storing-input-form"
                                                onChange={(e) => {
                                                    setStorageAdress(e.target.value)
                                                }}
                                                required
                                                placeholder='Введите адрес на складе'
                                    />
                                    <Button buttonType='white-shadowed' className="back-button end-button" onClick={handleStorageFinish}>Завершить
                                        задачу
                                    </Button>
                                </div>

                            </div> 
                        </FieldMain> : <></>
                }
                {
                    (props.task.task.type === TaskType.Logistics) ?
                        <LogisticsTaskInfo task={props.task}/>  : <></>
                }
                {
                    (props.task.task.type === TaskType.QualityControl) ?
                        <FieldMain className="special-info-item">
                            <div className="special-info-container-item">
                                
                                <div>
                                    <p className="sub-end-task">Количество деталей для проверки: {props.task?.amount}</p>
                                    <p style={{ marginBottom: "0px"}} className="sub-end-task">Количество деталей прошедшие контроль</p>
                                    <InputNumber value={qualified} onValueChange={(e: InputNumberValueChangeEvent) => setQualified(e.value || 0)} mode="decimal" showButtons min={0} max={props.task.amount} />
                                    <Button buttonType='white-shadowed' className="back-button end-button" onClick={handleQCFinish}>Завершить
                                        задачу
                                    </Button>
                                </div>

                            </div> 
                        </FieldMain> : <></>
                }
                <FieldMain className='secondary-description'>
                                <div className="sub-project-info-note">
                                    <h4>Примечание:</h4>
                                    <EditableText
                                        initialValue="" 
                                        placeholder='Введите примечание'
                                        className="w-full special-info-item-description-input form-control"
                                        />
                                </div>
                                
                                <div className="sub-project-info-note">
                                    <h4>Замечание:</h4>
                                    <EditableText
                                        initialValue=""
                                        placeholder='Введите замечание'
                                        className="w-full special-info-item-description-input form-control"
                                        />

                                </div> 
                </FieldMain>
            </div>
            {(props.task.task.type === TaskType.Manufacturing) ?
                            <FieldMain className='disable-hover-activ-field'style={{ marginTop: "10px", padding: "20px"}}>
                                <ChildTasksInfo childTasks={props.task.task.child_tasks}/>
                            </FieldMain> : <></>
            }
            {(isDelete) ?
                <div className="modal display-block">
                    <div className="modal-main">
                        <h2>Удалить задачу?</h2>
                        <div className="new-detail-button-container">
                            <Button className="btn btn-lg details-default-button" onClick={handleClose}>Нет
                            </Button>
                            <Button className="btn btn-lg details-default-button" onClick={handleDelete}>Да
                            </Button>
                        </div>
                    </div>
                </div>
                : <></>
            }
        </div>

    )
        ;
};

export default TaskComponent;