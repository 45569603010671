import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from '../../../components/Button/Button';
import { instance } from '../../../api/config';
import { AmountTypes } from '../../../entities/material_type';
import './NewMaterialType.css'

interface NewMaterialTypeFormProps {
    isChange: boolean;
    setIsChange: (value: boolean) => void;
    isAdd: boolean;
    setIsAdd: (value: boolean) => void;
}
const NewMaterialTypeForm = (props: NewMaterialTypeFormProps) => {

    const [name, setName] = useState("")
    const [amountType, setAmountType] = useState("")

    const handleClose = async (e: React.FormEvent) => {
        e.preventDefault();
        props.setIsAdd(false);
    }

    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();
        const resp = await instance.post("/api/materialTypes", {
            "name": name,
            "amount_type": amountType,
        })
        props.setIsAdd(false);
        props.setIsChange(!props.isChange)
    }

    return (
        <div>
            <div className='new-material-form-container'>
                <p>Название:</p>
                <Form.Control
                    className='form-control new-material-input-form'
                    placeholder="Введите название"
                    onChange={(e) => {setName(e.target.value)}}
                >
                </Form.Control>
            </div>
            <div className='new-material-form-container'>
                <p>Единаца измерения: </p>
                <Form.Select aria-label="Task Type"
                                        id="taskType"
                                        className="form-control new-material-input-form"
                                        value={amountType || ""}
                                        onChange={(e) => setAmountType(e.target.value)}
                                        required
                            >
                                <option value="" disabled>Выберите единицу измерения</option>
                                {Object.values(AmountTypes).map((amountType, index) => (
                                    <option key={index} value={amountType}>{amountType}</option>
                                ))}
                </Form.Select>
            </div>
            <div className="new-detail-button-container" style={{display: "flex", width: "50%"}}>
                <Button buttonType='white-shadowed' className="w-100" onClick={handleClose}>Закрыть
                </Button>
                <Button buttonType='white-shadowed' className="w-100" style={{ marginLeft: "10px"}} onClick={handleSave}>Сохранить
                </Button>
            </div>
        </div>
    )
}

export default NewMaterialTypeForm;