import AppRoutes from "../routes/AppRoutes";
import React from "react";
import './App.css';
import {Toaster} from "react-hot-toast";
import PrimeReact from "primereact/api";
import { useTheme } from "../hooks/UseTheme";


const App =() => {
    useTheme()
    return (
        <div className="App">
            <div><Toaster/></div>
            <AppRoutes/>
        </div>
    );
};

export default App;