import {Operation} from "./operation";
import {NewShortStorage, ShortStorage} from "./storage";

export interface RouteList {
    ID: number | null,
    operations: Operation[] | [],
    number: string | null,
    storage_id: number | null,
    storage: ShortStorage,
}

export const NewRouteList = () : RouteList => {
    return {
        ID: null,
        operations: [],
        number: null,
        storage_id: null,
        storage: NewShortStorage(),
    }
}