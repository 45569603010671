import React, { useEffect, useState } from 'react';
import {ShortStorage} from "../../../entities/storage";
import {List, MantineProvider} from "@mantine/core";
import './StorageListItem.css'
import DetailsCountListItem from '../DetailsCountListItem/DetailsCountListItem';
import DetailsCountList from '../../Lists/DetailsCountList/DetailsCountList';
import { FieldGeneral, FieldMain } from '../../../components/Field/Fields';
import { CircularProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { buildStyles } from 'react-circular-progressbar';
import { instance } from '../../../api/config';
import { FullTaskResponse } from '../../../entities/task';
interface StorageListItemProps {
    storage: ShortStorage
}
const StorageListItem = (props: StorageListItemProps) => {
    const detail = props.storage.details_with_count?.at(0)
    const [task, setTask] = useState<FullTaskResponse>()

    useEffect(() => {
        const getMFTask = async () => {
            if (detail?.manufacturing_task_id) {
            const resp = await instance.get("/api/tasks", {
                params: {"ID": detail?.manufacturing_task_id}
            })
            setTask(resp.data)
        }
        }
        getMFTask()
    }, [])
    const detailProgress = task?.task.progress

    return (
        <FieldMain className="storage-list-item" >
            <div className='storage-list-item-header'>
                <h2>{props.storage.name}</h2>
                <h6>Адрес: {props.storage.address}</h6>
            </div>
            { (detail?.ID) ?
            <FieldGeneral className='storage-list-item-detail'>
                <div className='storage-list-item-detail-progress-bar'>
                    <CircularProgressBar value={detailProgress} styles={buildStyles({ textColor: '#F65727', pathColor: "#F65727"})}></CircularProgressBar>
                </div>
                <div>
                    <h6>{detail?.detailType?.name}</h6>
                    <h6>Обозначение: {detail?.detailType?.number}</h6>
                    <h6>Количество: {detail?.count}</h6>
                </div>
            </FieldGeneral> : <></>
            }
        </FieldMain>
    )
}

export default StorageListItem;