import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import './NewPurchaseTask.css'
import { MaterialType } from "../../../../entities/material_type";
import { instance } from "../../../../api/config";
import { ShortStorage, StorageTypes } from "../../../../entities/storage";
import Switch from 'react-switch'

interface NewPurchaseTaskFormProps {
    gost: string
    setGost: (gost: string) => void
    size: string
    setSize: (size: string) => void
    quantity: number
    setQuantity: (quantity: number) => void
    isInBudget: boolean
    setIsInBudget: (isInBudget: boolean) => void
    predictedPrice: number
    setPredictedPrice: (predictedPrice: number) => void
    supplyProvider: string
    setSupplyProvider: (supplyProvider: string) => void
    supplyWeight: number
    setSupplyWeight: (supplyWeight: number) => void
    supplyTimeAfterPaymentFrom: number
    setSupplyTimeAfterPaymentFrom: (supplyTimeAfterPaymentFrom: number) => void
    supplyTimeAfterPaymentTo: number
    setSupplyTimeAfterPaymentTo: (supplyTimeAfterPaymentTo: number) => void
    predictedSupplyDate: string
    setPredictedSupplyDate: (predictedSupplyDate: string) => void
    plannedSupplyDate: string
    setPlannedSupplyDate: (supplyDate: string) => void
    supplyDate: string
    setSupplyDate: (supplyDate: string) => void
    materialTypeID: number
    setMaterialTypeID: (materialTypeID: number) => void
    storageID: number
    setStorageID: (storageID: number) => void
}

const NewPurchaseTaskForm = (props: NewPurchaseTaskFormProps) => {

    const [materialTypes, setMaterialTypes] = useState<MaterialType[]>([])
    const [storages, setStorages] = useState<ShortStorage[]>([])

    useEffect(() => {
        const getDetails = async () => {
            const resp = await instance.get("/api/materialTypes/all")
            setMaterialTypes(resp.data)
        }
        const getStorages = async () => {
            const resp = await instance.get("/api/storage/all")
            setStorages(resp.data)
        }
        getStorages()
        getDetails()
    }, []);

    return (
        <div>
            <div className="sub-page-task-purchase" style={{ display: "flex", justifyContent: "space-between"}}>
                <div className="manufacturing-form mb-3" style={{ width: "50%", marginRight: "10px"}}>
                    <Form.Select aria-label="Material"
                                  id="taskMaterial"
                                  defaultValue="0"
                                  className="form-control add-purchase-task-input-form"
                                  onChange={(e) => {
                                      props.setMaterialTypeID(parseInt(e.target.value))
                                  }}
                                  required
                    >
                        <option value="0" disabled>Введите тип материала</option>
                        {Object.values(materialTypes).map((materialType) => (
                            <option key={materialType.ID} value={materialType?.ID || 0}>
                                {materialType.name}
                            </option>
                        ))}

                    </Form.Select>
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "50%" }}>
                    <Form.Control aria-label="Gost"
                                  id="taskGost"
                                  className="form-control add-purchase-task-input-form"
                                  value={props.gost || ""}
                                  onChange={(e) => {
                                      props.setGost(e.target.value)
                                  }}
                                  required
                                  placeholder="Введите ГОСТ"
                    ></Form.Control>
                </div>
            </div>
            <div className="sub-page-task-purchase" style={{ display: "flex", justifyContent: "space-between"}}>
                <div style={{ width: "50%", marginRight: "10px"}} >
                    <Form.Select aria-label="Material"
                                  id="taskMaterial"
                                  defaultValue="0"
                                  className="form-control add-purchase-task-input-form"
                                  onChange={(e) => {
                                      props.setStorageID(parseInt(e.target.value))
                                  }}
                                  required
                    >
                        <option value="0" disabled>Выберите склад</option>
                        {storages.filter(s => s.storage_type == StorageTypes.Materials ).map((storage) => (
                            <option key={storage.ID} value={storage?.ID || 0}>
                                {storage.name}
                            </option>
                        ))}

                    </Form.Select>
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "25%", marginRight: "10px"}}>
                    <Form.Control aria-label="Quantity"
                                  id="Quantity"
                                  className="form-control add-purchase-task-input-form"
                                  value={props?.quantity || ""}
                                  onChange={(e) => props.setQuantity(parseInt(e.target.value))}
                                  required
                                  placeholder="Введите количество"
                    />
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "25%" }}>
                    <Form.Control aria-label="size"
                                  id="size"
                                  className="form-control add-purchase-task-input-form"
                                  value={props?.size}
                                  onChange={(e) => props.setSize(e.target.value)}
                                  required
                                  placeholder="Введите размер"
                    />
                </div>
            </div>
            <div className="sub-page-task-purchase" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "60px"}}>
                <div className="manufacturing-form-big mb-3" style={{ width: '60%'}}>
                    <Form.Control aria-label="predictedPrice"
                                  id="predictedPrice"
                                  className="form-control add-purchase-task-input-form"
                                  placeholder='Запланированная цена, рубли'
                                  onChange={(e) => props.setPredictedPrice(parseInt(e.target.value))}
                                  required
                    />
                </div>
                <div style={{ marginBottom: "15px"}}>
                    <Switch uncheckedIcon={false} checkedIcon={false} onColor="#F65727" offColor="#100f12" checked={props.isInBudget} onChange={() => {props.setIsInBudget(!props.isInBudget)}}/>
                </div>
                <div>
                    <p>Входит в бюджет</p>
                </div>
            </div>
            <div className="sub-page-task-purchase" style={{ display: "flex", justifyContent: "space-between"}}>
                <div className="manufacturing-form mb-3" style={{ width: "50%", marginRight: "10px"}}>
                    <Form.Control aria-label="supplyProvider"
                                  id="supplyProvider"
                                  className="form-control add-purchase-task-input-form"
                                  maxLength={50}
                                  value={props?.supplyProvider || ""}
                                  onChange={(e) => props.setSupplyProvider(e.target.value)}
                                  required
                                  placeholder="Введите поставщика"
                    />
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "50%" }}>
                    <Form.Control aria-label="supplyWeight"
                                  id="supplyWeight"
                                  className="form-control add-purchase-task-input-form"
                                  value={props?.supplyWeight || ""}
                                  onChange={(e) => props.setSupplyWeight(parseInt(e.target.value))}
                                  required
                                  placeholder="Введите вес поставки"
                    />
                </div>
            </div>
            <div className="">

                <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%", alignItems: "center" }}>
                    <div style={{ marginRight: "10px" }}>
                        <p style={{color: 'var(--primary-text-color)'}}>Срок поставки после оплаты счета, дни</p>
                    </div>
                    <div className="manufacturing-form" style={{ width: "31%", marginRight: "10px"}}>
                        <Form.Control aria-label="supplyTimeAfterPaymentFrom"
                                      id="supplyTimeAfterPaymentFrom"
                                      className="form-control add-purchase-task-input-form"
                                      value={props?.supplyTimeAfterPaymentFrom || ""}
                                      onChange={(e) => props.setSupplyTimeAfterPaymentFrom(parseInt(e.target.value))}
                                      required
                                      placeholder="От"
                        />
                    </div>
                    <div className="manufacturing-form" style={{ width: "31%"}}>
                        <Form.Control aria-label="supplyWeight"
                                      id="supplyWeight"
                                      className="form-control add-purchase-task-input-form"
                                      value={props?.supplyTimeAfterPaymentTo || ""}
                                      onChange={(e) => props.setSupplyTimeAfterPaymentTo(parseInt(e.target.value))}
                                      required
                                      placeholder="До"
                        />
                    </div>
                </div>
            </div>
            <div className="sub-page-task-purchase" style={{ display: "flex", justifyContent: "space-between"}}>
                <div style={{ width: "60%"}}>
                    <p>Ожидаемая дата поставки</p>
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "50%", marginRight: "10px"}}>
                    
                    <Form.Control aria-label="predictedSupplyDate"
                                  id="predictedSupplyDate"
                                  className="form-control add-purchase-task-input-form"
                                  value={props?.predictedSupplyDate || ""}
                                  onChange={(e) => props.setPredictedSupplyDate(e.target.value)}
                                  type="date"
                                  required
                    />
                </div>
                <div style={{ width: "60%"}}>
                    <p>Планируемая дата поставки</p>
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "50%" }}>
                    
                    <Form.Control aria-label="plannedSupplyDate"
                                  id="plannedSupplyDate"
                                  className="form-control add-purchase-task-input-form"
                                  value={props?.plannedSupplyDate || ""}
                                  onChange={(e) => props.setPlannedSupplyDate(e.target.value)}
                                  type="date"
                                  required
                    />
                </div>
            </div>
        </div>
    )
}
export default NewPurchaseTaskForm