// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storage-list-item {
    height: 120px;
    padding: 20px;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
    margin-bottom: 10px !important;
    align-items: center !important;
}

.storage-list-item:hover{
    box-shadow: 0 0 10px #F65727;
}
.storage-list-item:active{
    background-color: var(--button-background-color-active);
    box-shadow: 0 0 10px #F65727;
}

.storage-list-item-header {
    margin-left: 10px;
    margin-right: 10px; 
    height: max-content; 
    align-content: center; 
}

.storage-list-item-header h2 {
    color: var(--primary-text-color);
}

.storage-list-item-header h6 {
    color: var(--secondary-text-color);
}

.storage-list-item-detail {
    align-items: center;
    padding: 10px;
    display: flex;
    width: auto;
}

.storage-list-item-detail-progress-bar {
    height: 60px;
    width: 60px;
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ListItems/StorageListItem/StorageListItem.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,8BAA8B;IAC9B,8BAA8B;IAC9B,8BAA8B;AAClC;;AAEA;IACI,4BAA4B;AAChC;AACA;IACI,uDAAuD;IACvD,4BAA4B;AAChC;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".storage-list-item {\n    height: 120px;\n    padding: 20px;\n    display: flex;\n    border-radius: 8px;\n    justify-content: space-between;\n    margin-bottom: 10px !important;\n    align-items: center !important;\n}\n\n.storage-list-item:hover{\n    box-shadow: 0 0 10px #F65727;\n}\n.storage-list-item:active{\n    background-color: var(--button-background-color-active);\n    box-shadow: 0 0 10px #F65727;\n}\n\n.storage-list-item-header {\n    margin-left: 10px;\n    margin-right: 10px; \n    height: max-content; \n    align-content: center; \n}\n\n.storage-list-item-header h2 {\n    color: var(--primary-text-color);\n}\n\n.storage-list-item-header h6 {\n    color: var(--secondary-text-color);\n}\n\n.storage-list-item-detail {\n    align-items: center;\n    padding: 10px;\n    display: flex;\n    width: auto;\n}\n\n.storage-list-item-detail-progress-bar {\n    height: 60px;\n    width: 60px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
