import React from 'react';
import './WorkPlacesList.css'
import { WorkPlace } from '../../../entities/workplaces';
import WorkPlacesListItem from '../../ListItems/WorkPlacesListItem/WorkPlacesListItem';

interface WorkPlacesListProps {
    workplaces: WorkPlace[]
}
const WorkPlacesList = (props: WorkPlacesListProps) => {
    return (
        <div className='workplaces-list'>
        {
            props.workplaces.map((workplace: WorkPlace) => (
                <WorkPlacesListItem workplace={workplace} />
            ))
        }
        </div>
    )
}

export default WorkPlacesList;