// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-menu{
    margin: 10px;
}

.sub-nav-menu{
    display: flex;
    justify-content: space-between;
    width: 100%
}
.form-floating-task-info{
    display: flex;
    justify-content: space-between;
}

.sub-button{
    width: 49%;
}

.mb-3 p{
    color: white;
}

div p{
    color: white;
}

div textarea{
    resize: none;
    width: 100%;
}

.add-task-button {
    background-color: #F65727;
    border-color: #F65727;
    color: #212529
}

.add-task-button:hover {
    background-color: #F65727;
    color: #1d2124 !important;
    border: solid 1px #F65727 !important;
    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;
}

.add-task-button:active {
    background-color: #212529;
    color: #F65727
}

.add-task-input-form {
    border: 2px solid #F65727;
    border-radius: 8px;
    margin-bottom: 20px;
}

.add-task-input-form:focus {
    border: 2px solid #F65727;
    border-radius: 8px;
    box-shadow: 0 0 0 0;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Forms/NewTaskForm/NewTaskForm.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B;AACJ;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB;AACJ;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,oCAAoC;IACpC,8DAA8D;AAClE;;AAEA;IACI,yBAAyB;IACzB;AACJ;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".sub-menu{\n    margin: 10px;\n}\n\n.sub-nav-menu{\n    display: flex;\n    justify-content: space-between;\n    width: 100%\n}\n.form-floating-task-info{\n    display: flex;\n    justify-content: space-between;\n}\n\n.sub-button{\n    width: 49%;\n}\n\n.mb-3 p{\n    color: white;\n}\n\ndiv p{\n    color: white;\n}\n\ndiv textarea{\n    resize: none;\n    width: 100%;\n}\n\n.add-task-button {\n    background-color: #F65727;\n    border-color: #F65727;\n    color: #212529\n}\n\n.add-task-button:hover {\n    background-color: #F65727;\n    color: #1d2124 !important;\n    border: solid 1px #F65727 !important;\n    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;\n}\n\n.add-task-button:active {\n    background-color: #212529;\n    color: #F65727\n}\n\n.add-task-input-form {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    margin-bottom: 20px;\n}\n\n.add-task-input-form:focus {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    box-shadow: 0 0 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
