import React from 'react';
import {Project} from "../../../entities/project";
import { ProgressBar } from 'primereact/progressbar';
import {Card} from "primereact/card";
import {Ripple} from "primereact/ripple";
import {Tag} from "primereact/tag";
import {Divider} from "primereact/divider";
import {useNavigate} from "react-router-dom";
import './ProjectListItem.css'
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/bootstrap4-dark-purple/theme.css';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

interface ProjectProps {
    project: Project
    departmentID: number
}

const ProjectListItem: React.FC<ProjectProps> = (props) => {
    const navigate = useNavigate();
    let finish_date;
    if (!props.project.finish_date) {
        finish_date = "";
    } else {
        finish_date = props.project.finish_date;
    }

    return (
            <Card className="project" onClick={e => {
            e.preventDefault();
            navigate(`/projects/${props.project.ID}`);
        }}>
                <div className='project-content'>
                    <div className='project-general-info'>
                        <div className='project-progress-bar'>
                            <CircularProgressbar styles={buildStyles({textColor: '#F65727', pathColor: "#F65727"})} value={props.project.progress}/>
                        </div>
                        <label>{`${props.departmentID}. ${props.project.ID}.`}</label>
                        <label className='project-name-info'>{props.project.name}</label>
                    </div>

                    <div className='project-date-info' >
                        <label >Планируемая дата завершения: {finish_date.slice(0, 10)}</label>
                    </div>
                </div>
            </Card>
    );
};

export default ProjectListItem;