// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.operations-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
    margin-top: 10px;
    
}

.operations-form p {
    margin-right: 10px;
    margin-left: 10px;
    color: var(--primary-text-color);
}

.operations-form:last-child {
    margin-right: 0;
}
.add-operation-button {
    background-color: #F65727;
    color: #212529;

}
.add-operation-button:hover {
    background-color: #F65727;
    color: #1d2124 !important;
    border: solid 1px #F65727 !important;
    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;
}

.add-operation-button:active {
    background-color: #212529;
    color: #F65727;

}

.delete-operation-button {
    margin-left: 10px;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Forms/NewOperationForm/NewOperationForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,SAAO;IACP,gBAAgB;;AAEpB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,gCAAgC;AACpC;;AAEA;IACI,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,cAAc;;AAElB;AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,oCAAoC;IACpC,8DAA8D;AAClE;;AAEA;IACI,yBAAyB;IACzB,cAAc;;AAElB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".operations-form{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    flex: 1;\n    margin-top: 10px;\n    \n}\n\n.operations-form p {\n    margin-right: 10px;\n    margin-left: 10px;\n    color: var(--primary-text-color);\n}\n\n.operations-form:last-child {\n    margin-right: 0;\n}\n.add-operation-button {\n    background-color: #F65727;\n    color: #212529;\n\n}\n.add-operation-button:hover {\n    background-color: #F65727;\n    color: #1d2124 !important;\n    border: solid 1px #F65727 !important;\n    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;\n}\n\n.add-operation-button:active {\n    background-color: #212529;\n    color: #F65727;\n\n}\n\n.delete-operation-button {\n    margin-left: 10px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
