import styled from 'styled-components'

export const DefaultButton = styled.button

export const Button = styled.button<{buttonType?: string}>`
  ${props => (props.buttonType === "white-shadowed") ? 
  `
    background-color: var(--white-shadowed-button-background-color);
    border: 0px solid var(--white-shadowed-button-border-color);
    color: var(--primary-text-color);
    box-shadow: 0 0.1em 0.30em  #757575;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    height: 40px;
    font-size: small;
    font-weight: 600;
    &:hover {
      border: solid 0px #F65727 !important;
      box-shadow: 0 0 10px #F65727 !important;
    }
    &:active {
      background-color: var(--white-shadowed-button-background-color-active);
    }
  ` : `
    background-color: var(--button-background-color);
    border: 0px solid var(--button-border-color);
    box-shadow: 0 0 0 0.01em  #757575;
    color: var(--primary-text-color);
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    
    &:hover {
      border: solid 0px #F65727 !important;
      box-shadow: 0 0 10px #F65727 !important;
    }
    &:active {
      background-color: var(--button-background-color-active);
    }  
    &:focus {
      background-color: var(--button-background-color-active);
      box-shadow: 0 0 0 0;
    } 
  `
  }
`

;

export const ButtonDropdown = styled.button`
  padding-left: 5%;
  font-size: 16px;
  width: 100%;
  min-height: 40px;
  min-width: 200px;
  box-sizing: border-box;
  text-align: left;
  background: var(--buttondropdown-background-color);
  border-top: transparent;
  border-bottom: transparent;
  border-left: none;
  border-right: none;
  border: 2px solid transparent;
  overflow: hidden;
  backdrop-filter: blur(2px);
  &:hover {
    background: linear-gradient(var(--buttondropdown-background-color-hover), var(--buttondropdown-background-color-hover) 0) padding-box,
    linear-gradient(to right, var(--buttondropdown-border-color-hower-left), var(--buttondropdown-border-color-hower-right)) border-box;
    -webkit-mask: linear-gradient(var(--buttondropdown-background-color-hover-webkit) 0 0) padding-box, linear-gradient(var(--buttondropdown-background-color-hover-webkit-right) 0 0);
    mask-composite: exclude;
    }
  &:active{
    background: linear-gradient(var(--buttondropdown-background-color-active), var(--buttondropdown-background-color-active) 0) padding-box,
    linear-gradient(to right,var(--buttondropdown-border-color-active-left), var(--buttondropdown-border-color-active-right)) border-box;
    /* box-shadow: inset 0 0 5px rgb(246, 87, 39), 0 0 15px #F65727; */
  }
`

export const TaskButton = styled.button`
  
`