import React, { useEffect, useState } from 'react';
import { ShortStorage, StorageTypes } from '../../../entities/storage';
import { Operation } from '../../../entities/operation';
import { DetailType, NewDetailType } from '../../../entities/detail_type';
import { instance } from '../../../api/config';
import { Form } from 'react-bootstrap';
import NewOperationsForm from '../NewOperationForm/NewOperationForm';
import './NewDetailTypeForm.css'
import { Button } from '../../../components/Button/Button';

interface NewDetailTypeFormProps {
    isChanged: boolean;
    setIsChanged: (value: boolean) => void;
    setIsAdd: (value: boolean) => void;
}

const NewDetailTypeForm = (props: NewDetailTypeFormProps) => {

    const [storageList, setStorageList] = useState<ShortStorage[]>([])
    const [storage, setStorage] = useState<ShortStorage>()
    const [operations, setOperations] = useState<Operation[]>([]);
    const [detailType, setDetailType] = React.useState<DetailType>(NewDetailType);

    useEffect(() => {
        const getStorages = async () => {
            const resp = await instance.get("/api/storage/all")
            setStorageList(resp.data.filter((item: ShortStorage) => item.storage_type === StorageTypes.Details))
        }
        getStorages()
    }, []);

    const handleClose = async (e: React.FormEvent) => {
        e.preventDefault();
        props.setIsAdd(false);
    }

    const onDetailNameChange = (name: string) => {
        const newDetailType = detailType;
        newDetailType.name = name;
        setDetailType(newDetailType);
        props.setIsChanged(!props.isChanged)
    }

    const onDetailNumberChange = (number: string) => {
        const newDetailType = detailType;
        newDetailType.number = number;
        setDetailType(newDetailType);
        props.setIsChanged(!props.isChanged)
    }

    const handleSave = async (e: React.FormEvent) => {
        const newDetailRequestData = {
            name: detailType.name,
            number: detailType.number,
            route_list: {
                number: detailType.number,
                operations: operations,
                storage_id: storage?.ID,
            }
        }
        await instance.post("/api/detailTypes", newDetailRequestData);
        props.setIsAdd(false)
        const respAllDetails = await instance.get("/api/detailTypes/all")

        setDetailType(respAllDetails.data)
        props.setIsChanged(!props.isChanged)
        setDetailType(NewDetailType());
        setStorage(undefined)
        setOperations([])
    }

    return (
        <div className="display-block">
                <div>
                    <div className="new-detail-form-container" style={{width: "50%"}}>
                        <p>Название детали</p>
                        <input
                            id="DetailName"
                            className="form-control new-detail-input-form"
                            value={detailType.name || ""}
                            onChange={(e) => onDetailNameChange(e.target.value)}
                            placeholder='Введите название детали'
                            required
                        />
                        <p>Обозначение детали</p>
                        <input
                            id="DetailNumber"
                            className="form-control new-detail-input-form"
                            value={detailType.number || ""}
                            onChange={(e) => onDetailNumberChange(e.target.value)}
                            placeholder='Введите обозначение детали'
                            required
                        />
                    </div>
                    <div className="new-detail-form-container">
                        <p>Тип склада</p>
                        <Form.Select aria-label="Task Type"
                                     id="taskType"
                                     className="form-control new-detail-input-form"
                                     value={storage?.name || ""}
                                     onChange={(e) => setStorage(storageList[parseInt(e.target.value)])}
                                     required
                        >
                            <option value="" disabled>Выберите склад</option>
                            {Object.values(storageList).map((storage, index) => (
                                <option key={index} value={index}>{storage.name}</option>
                            ))}
                        </Form.Select>
                    </div>
                    <div>
                        <NewOperationsForm operations={operations} setOperations={setOperations}/>
                    </div>
                    <div className="new-detail-button-container" style={{display: "flex", width: "50%"}}>
                        <Button buttonType='white-shadowed' className="w-100" onClick={handleClose}>Закрыть
                        </Button>
                        <Button buttonType='white-shadowed' className="w-100" style={{ marginLeft: "10px"}} onClick={handleSave}>Сохранить
                        </Button>
                    </div>

                </div>
            </div>
    )
}

export default NewDetailTypeForm;