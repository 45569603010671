import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {LongStorage, ShortStorage, StorageTypes} from "../../entities/storage";
import {instance} from "../../api/config";
import { Form } from 'react-bootstrap';
import { DetailCount } from '../../entities/detail';
import DetailsCountList from '../../widgets/Lists/DetailsCountList/DetailsCountList';
import './StoragePage.css'
import MaterialList from '../../widgets/Lists/MaterialList/MaterialList';
const StoragePage = () => {

    const { id } = useParams()

    const [storage, setStorage] = useState<ShortStorage>()
    const [allDetails, setAllDetails] = useState<DetailCount[] | null>()
    const [isChanged, setIsChanged] = useState(false)
    useEffect(() => {
        const getLongStorage = async () => {
            const resp = await instance.get("/api/storage", { params: {
                "ID": id
                }})
            setStorage(resp.data)
            setAllDetails(resp.data.details_with_count)
        }
        getLongStorage()
    }, []);

    if (storage === undefined || storage === null) {
        return (
            <>Ошибка, обратитесь к администратору</>
        )
    }
    
    return (
        <div className='storage'>
            <div className='storage-header'>
                <div>
                    <h1 className='storage-header-name'>{storage?.name}</h1>
                    <h6>Адрес: {storage?.address}</h6>
                </div>
                <Form.Control
                    className='form-control storage-header-search'
                    placeholder='Поиск по деталям'
                    onChange={(e) => {
                        const newDetails = allDetails?.filter(d => d.detailType?.name?.toLowerCase().includes(e.target.value.toLowerCase()) || d.detailType?.number?.toLowerCase().includes(e.target.value.toLowerCase())) || null
                        storage.details_with_count = newDetails
                        console.log(storage.details_with_count?.length)
                        setIsChanged(!isChanged)
                        setStorage(storage)
                    }}
                > 
                </Form.Control>
                
            </div>
            {(storage.storage_type === StorageTypes.Details) ?
                <div>
                    <DetailsCountList details={storage.details_with_count || []} />
                </div> : <></>
            }
            {(storage.storage_type === StorageTypes.Materials) ?
                <div>
                    <MaterialList materials={storage.materials || []} />
                </div> : <></>
            }
        </div>
    )
}

export default StoragePage;