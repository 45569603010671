import {Project} from "./project";
import {Operation} from "./operation";
import {Detail} from "./detail";
import {User} from "./user";
import { DetailType } from "./detail_type";
import { WorkPlace } from "./workplaces";

export interface ShortTaskResponse {
    ID: number;
    projectId: number;
    finish_date: string;
    project: Project
    type: TaskType;
    name: string;
    description: string;
    responsible: User;
    executor_id: number;
    executor: User;
    progress: number;
    parent_task: ShortTaskResponse;
    parent_task_id: number;
    child_tasks: ShortTaskResponse[];
    prev_task_id: number;
    next_task_id: number;
    visible: boolean
    status: boolean
}

export interface FullTaskResponse {
    ID: number
    note: string,
    comment: string,
    initialValue?: string,
    detail?: Detail,
    quantity?: number,
    operations?: Operation[],
    operation?: Operation,
    task: ShortTaskResponse,
    material?: string
    gost?: string
    size?: string
    is_in_budget?: boolean
    predicted_price?: number
    supplier?: string
    supply_weight?: number
    supply_time_after_payment_from?: number
    supply_time_after_payment_to?: number
    predicted_supply_date?: string
    planned_supply_date?: string
    supply_date?: string
    detail_type?: DetailType
    work_place?: WorkPlace
    amount?: number
    qualified?: number
    done?: number
}

export interface AddTaskRequest {
        project_id: number,
        type: string,
        name: string,
        description: string,
        responsible_id: number,
        progress: number,
        operations?: Operation[],
        operation?: Operation,
        visible: boolean
        status: boolean
        parent_task_id: number | null,
        prev_task_id: number | null;
        next_task_id: number | null;
        start_date: string,
        finish_date: string,
        detail_type_id?: number
        quantity?: number
        material_type_id?: number
        storage_id?: number
        gost?: string
        size?: string
        is_in_budget?: boolean
        predicted_price?: number
        supplier?: string
        supply_weight?: number
        supply_time_after_payment_from?: number
        supply_time_after_payment_to?: number
        predicted_supply_date?: string
        planned_supply_date?: string
        supply_date?: string
        detail_type?: DetailType
        amount?: number
        qualified?: number
        operations_workplaces?: OperationWorkplace[]
        has_quality_control?: boolean

};

export interface OperationWorkplace {
    operation_id: number | null;
    work_place_id: number | null;
}

export type Task = ShortTaskResponse;

export interface TasksByProject {
    [ProjectName: string]: Task[];
}

export enum TaskType {
    General = "Общая",
    Manufacturing = "Заготовительная",
    Purchase = "Закупка",
    Operation = "Операция",
    Storing = "Складирование",
    Logistics = "Логистическая",
    QualityControl = "Контроль качества"
}

export const getTasksByProject = (tasks: Task[]): TasksByProject => {
    const tasksByProject: TasksByProject = {};

    tasks.forEach(task => {
        if (task.project.name) {
            if (!tasksByProject[task.project.name]) {
                tasksByProject[task.project.name] = [];
            }
            tasksByProject[task.project.name].push(task);
        }
    });

    return tasksByProject;
}