import React from 'react';
import MaterialListItem from '../../ListItems/MaterialListItem/MaterialListItem';
import { Material } from '../../../entities/material';

interface MaterialListProps {
    materials: Material[]
}
const MaterialList = (props : MaterialListProps) => {
    return (
            <div className="details-count-list">
                {props.materials.map((material: Material) => (
                    <MaterialListItem
                        material={material}
                    />
                ))}
            </div>
            )
}

export default MaterialList;