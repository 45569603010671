// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-count-list {
    display: flex;
    flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Lists/DetailsCountList/DetailsCountList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":[".details-count-list {\n    display: flex;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
