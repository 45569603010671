// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storage {
    margin-left: 80px; 
    margin-right: 80px;
}

.storage-header {
    color: var(--primary-text-color);
    margin-top: 40px;
    height: 40px;
    margin-bottom: 80px;
    display: flex;
    justify-content: safe;
}

.storage-header-name {
    margin-bottom: 0px;
    margin-right: 40px;
}

.storage-header-search {
    width: 60%;
    border-radius: 8px;
    height: 60px;
}

.storage-header-search:focus {
    border: 2px solid #F65727;
    box-shadow: 0 0 0 0 
}`, "",{"version":3,"sources":["webpack://./src/pages/StoragePage/StoragePage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB;AACJ","sourcesContent":[".storage {\n    margin-left: 80px; \n    margin-right: 80px;\n}\n\n.storage-header {\n    color: var(--primary-text-color);\n    margin-top: 40px;\n    height: 40px;\n    margin-bottom: 80px;\n    display: flex;\n    justify-content: safe;\n}\n\n.storage-header-name {\n    margin-bottom: 0px;\n    margin-right: 40px;\n}\n\n.storage-header-search {\n    width: 60%;\n    border-radius: 8px;\n    height: 60px;\n}\n\n.storage-header-search:focus {\n    border: 2px solid #F65727;\n    box-shadow: 0 0 0 0 \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
