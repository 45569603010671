// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-page-task-purchase div > p{
    color: black;
}

.add-purchase-task-input-form {
    border: 2px solid #F65727;
    border-radius: 8px;
    margin-bottom: 20px;
}

.add-purchase-task-input-form:focus {
    border: 2px solid #F65727;
    border-radius: 8px;
    box-shadow: 0 0 0 0;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Forms/NewTaskForm/NewPurchaseTask/NewPurchaseTask.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".sub-page-task-purchase div > p{\n    color: black;\n}\n\n.add-purchase-task-input-form {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    margin-bottom: 20px;\n}\n\n.add-purchase-task-input-form:focus {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    box-shadow: 0 0 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
