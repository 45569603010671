import {Detail, DetailCount} from "./detail";
import { Material } from "./material";

export interface ShortStorage {
    ID: number | null,
    name: string | null,
    address: string | null,
    storage_type: string | null
    details_with_count: DetailCount[] | null,
    materials: Material[] | null
}

export interface LongStorage {
    id: number,
    name: string,
    address: string,
    storage_type: string
    details: Detail[]
    materials: Material[] | null
}

export enum StorageTypes {
    Materials = "Материалы",
    Details = "Детали",
    Accessories = "Комплектующие",
}

export const NewShortStorage = () : ShortStorage => {
    return {
        ID: null,
        name: null,
        address: null,
        storage_type: null,
        details_with_count: null,
        materials: null

    }
};