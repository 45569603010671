// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storage-list {
    margin-left: 20px;
    margin-right: 20px; 
    padding-left: 60px; 
    padding-right: 60px; 
    padding-bottom: 40px;
    margin-top: 20px;
}

.storage-list-header {
    display: flex;
    justify-content: space-between;
    color: var(--primary-text-color);
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
}

.storage-list-search {
    width: 60%;
    margin-left: 20px;
}

.storage-list-search-form {
    border-radius: 8px;
    height: 60px;
}

.storage-list-search-form:focus {
    border: 2px solid #F65727;
    box-shadow: 0 0 0 0 
}

.storage-list-new-storage-button {
    width: max-content;
    margin-left: 20px;
    height: 60px !important;
}

.storage-list-new-storage-button-content {
    display: flex;
    align-content: center;
    justify-content: center;
}

.new-storage {
    width: 30vw;
}`, "",{"version":3,"sources":["webpack://./src/pages/StorageListPage/StorageListPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gCAAgC;IAChC,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB;AACJ;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".storage-list {\n    margin-left: 20px;\n    margin-right: 20px; \n    padding-left: 60px; \n    padding-right: 60px; \n    padding-bottom: 40px;\n    margin-top: 20px;\n}\n\n.storage-list-header {\n    display: flex;\n    justify-content: space-between;\n    color: var(--primary-text-color);\n    align-items: center;\n    height: 40px;\n    margin-bottom: 20px;\n}\n\n.storage-list-search {\n    width: 60%;\n    margin-left: 20px;\n}\n\n.storage-list-search-form {\n    border-radius: 8px;\n    height: 60px;\n}\n\n.storage-list-search-form:focus {\n    border: 2px solid #F65727;\n    box-shadow: 0 0 0 0 \n}\n\n.storage-list-new-storage-button {\n    width: max-content;\n    margin-left: 20px;\n    height: 60px !important;\n}\n\n.storage-list-new-storage-button-content {\n    display: flex;\n    align-content: center;\n    justify-content: center;\n}\n\n.new-storage {\n    width: 30vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
