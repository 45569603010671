export interface Roles {
    [id: number] : string
}

export const Roles: Roles = {
    0: "Исполнитель",
    1: "Администратор",
    3: "Ведущий конструктор",
    4: "Директор",
    5: "Контролер качества",
    6: "Логист",
    7: "Складской менеджер"
}

