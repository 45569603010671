// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-workpalce-form-name-input {
    border: 2px solid #F65727;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.new-workpalce-form-address-input {
    border: 2px solid #F65727;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.new-workpalce-form-number-input {
    border: 2px solid #F65727;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.new-workplace-form-button {
    width: 100%;
}

.new-workplace-form-button-content {
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Forms/NewWorkPlaceForm/NewWorkPlaceForm.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".new-workpalce-form-name-input {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    margin-bottom: 20px;\n    margin-top: 20px;\n}\n\n.new-workpalce-form-address-input {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    margin-bottom: 20px;\n    margin-top: 20px;\n}\n\n.new-workpalce-form-number-input {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    margin-bottom: 20px;\n    margin-top: 20px;\n}\n\n.new-workplace-form-button {\n    width: 100%;\n}\n\n.new-workplace-form-button-content {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
