import React, { useEffect, useState } from 'react';
import { Task, TasksByProject } from '../../entities/task';
import { Button } from '../../components/Button/Button';
import './DayMonthYear.css'
interface DayMonthYearProps {
    tasks : Task[]
    setTasks : (tasks : Task[]) => void
    allTasks : Task[]
}

const DayMonthYear = (props: DayMonthYearProps) =>{
    
    const [chose, setChose] = useState("")

    const filterTasks = (selectedDate: Date) => {
        const newTasks = props.allTasks.filter(task => {
            const finishDate = new Date(
                parseInt(task.finish_date.slice(0, 4)),
                parseInt(task.finish_date.slice(5, 7)) - 1,
                parseInt(task.finish_date.slice(8, 10))
            );
            return finishDate < selectedDate;
        });
        props.setTasks(newTasks);
    };

    const handleDayClick = (e: React.FormEvent) => {
        e.preventDefault();
        const nowDate = new Date();
        nowDate.setDate(nowDate.getDate() + 1);
        filterTasks(nowDate);
        setChose("day")
    };

    const handleWeekClick = (e: React.FormEvent) => {
        e.preventDefault();
        const nowDate = new Date();
        nowDate.setDate(nowDate.getDate() + 7);
        filterTasks(nowDate);
        setChose("week")
    };

    const handleMonthClick = (e: React.FormEvent) => {
        e.preventDefault();
        const nowDate = new Date();
        nowDate.setMonth(nowDate.getMonth() + 1);
        filterTasks(nowDate);
        setChose("month")
    };

    return (
        <div className="btn-group btn-group-sm">
            <Button 
                className="day-button btn" 
                style={{ backgroundColor: chose === "day" ? "var(--button-background-color-active)" : "var(--button-background-color)" }}
                onClick={handleDayClick}>
                    
                День
            </Button>
            <Button 
                className="week-button btn"
                style={{ backgroundColor: chose === "week" ? "var(--button-background-color-active)" : "var(--button-background-color)" }}
                onClick={handleWeekClick}>
                Неделя
            </Button>
            <Button 
                className="month-button btn"
                style={{ backgroundColor: chose === "month" ? "var(--button-background-color-active)" : "var(--button-background-color)" }}
                onClick={handleMonthClick}>
                Месяц
            </Button>
        </div>
    );
}

export default DayMonthYear;