import React, { useState } from 'react';
import { instance } from '../../../api/config';
import { DetailType, NewDetailType } from '../../../entities/detail_type';

interface DeleteDetailTypeFormProps {
    setIsChanged: (value: boolean) => void;
    IsChanged: boolean;
    setIsDelete: (value: boolean) => void;
    setDeleteDetailType: (value: DetailType) => void;
    deleteDetailType: DetailType | undefined;
}
const DeleteDetailTypeForm = (props: DeleteDetailTypeFormProps) => {
    

    const handleDeleteClose = async (e: React.FormEvent) => {
        e.preventDefault()
        props.setIsDelete(false);
    }

    const handleDelete = async (ID: number) =>  {
        props.setIsDelete(false);
        const resp = instance.delete("/api/detailTypes", { data: {
                "ID": ID,
            }})
            props.setIsChanged(true)
    }

    return (
        
        <div className="modal display-block">
                <div className="modal-main">
                    <h2>Удалить деталь "{props.deleteDetailType?.name}" ?</h2>
                    <div className="new-detail-button-container" style={{display: "flex", width: "50%"}}>
                        <button className="w-50 btn btn-lg details-default-button" onClick={handleDeleteClose}>Нет
                        </button>
                        <button className="w-50 btn btn-lg details-default-button" onClick={async (e) => {
                            e.preventDefault()
                            props.setIsChanged(!props.IsChanged)
                            props.setIsDelete(false)
                                handleDelete(props.deleteDetailType?.ID || 0)
                                props.setDeleteDetailType(NewDetailType())
                            }}>Да</button>
                        </div>
                    </div>
                </div>
    )
}

export default DeleteDetailTypeForm;