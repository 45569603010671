// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workplaces {
    margin: 0px;
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
}

.workplaces-header {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.workplaces-header-search {
    width: 60%;
    margin-left: 10px;
    margin-right: 10px;
    
}

.workplaces-header-search-form {
    border-radius: 8px;
    height: 60px;
}

.workplaces-header-search-form:focus {
    border: 2px solid #F65727;
    box-shadow: 0 0 0 0 
}

.workplaces-page-list {
    margin-top: 40px;
    margin-left: 40px;
}
.new-workplace {
    width: 30vw;
    height: 30vw;
    border-radius: 8px;
}

.p-dialog {
    border: 0px;
    border-radius: 8px !important;
}
.p-dialog .p-dialog-header{
    color: var(--primary-text-color);
    background-color: var(--field-main-background-color);
    border-radius: 8px 8px 0px 0px;
    border: 0px
}

.p-dialog .p-dialog-content{
    color: var(--primary-text-color);
    background-color: var(--field-main-background-color);
}

.p-dialog .p-dialog-footer{
    color: var(--primary-text-color);
    background-color: var(--field-main-background-color);
    border-radius: 0px 0px 8px 8px ;
}`, "",{"version":3,"sources":["webpack://./src/pages/WorkPlacesPage/WorkPlacesPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;;AAEtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB;AACJ;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,6BAA6B;AACjC;AACA;IACI,gCAAgC;IAChC,oDAAoD;IACpD,8BAA8B;IAC9B;AACJ;;AAEA;IACI,gCAAgC;IAChC,oDAAoD;AACxD;;AAEA;IACI,gCAAgC;IAChC,oDAAoD;IACpD,+BAA+B;AACnC","sourcesContent":[".workplaces {\n    margin: 0px;\n    margin-top: 40px;\n    margin-left: 40px;\n    margin-right: 40px;\n}\n\n.workplaces-header {\n    padding-left: 40px;\n    padding-right: 40px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}\n\n.workplaces-header-search {\n    width: 60%;\n    margin-left: 10px;\n    margin-right: 10px;\n    \n}\n\n.workplaces-header-search-form {\n    border-radius: 8px;\n    height: 60px;\n}\n\n.workplaces-header-search-form:focus {\n    border: 2px solid #F65727;\n    box-shadow: 0 0 0 0 \n}\n\n.workplaces-page-list {\n    margin-top: 40px;\n    margin-left: 40px;\n}\n.new-workplace {\n    width: 30vw;\n    height: 30vw;\n    border-radius: 8px;\n}\n\n.p-dialog {\n    border: 0px;\n    border-radius: 8px !important;\n}\n.p-dialog .p-dialog-header{\n    color: var(--primary-text-color);\n    background-color: var(--field-main-background-color);\n    border-radius: 8px 8px 0px 0px;\n    border: 0px\n}\n\n.p-dialog .p-dialog-content{\n    color: var(--primary-text-color);\n    background-color: var(--field-main-background-color);\n}\n\n.p-dialog .p-dialog-footer{\n    color: var(--primary-text-color);\n    background-color: var(--field-main-background-color);\n    border-radius: 0px 0px 8px 8px ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
