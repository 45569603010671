import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Button } from '../../components/Button/Button'
import './MaterialTypesPage.css'
import { instance } from '../../api/config'
import MaterialTypesList from '../../widgets/Lists/MaterialTypesList/MaterialTypesList'
import { MaterialType } from '../../entities/material_type'
import { Dialog } from 'primereact/dialog'
import NewMaterialTypeForm from '../../widgets/Forms/NewMaterialTypeForm/NewMaterialType'
const MaterialTypesPage = () => {

    const [materials, setMaterials] = useState<MaterialType[]>([])
    const [allMaterials, setAllMaterials] = useState<MaterialType[]>([])
    const [isAdd, setIsAdd] = useState(false)
    const [isChange, setIsChange] = useState(false)

    useEffect(() => {
        const getMaterialTypesList = async () => {
            const resp = await instance.get("/api/materialTypes/all")
            setMaterials(resp.data)
            setAllMaterials(resp.data)
        }
        getMaterialTypesList()
    }, [isChange])

    return (
        <div className='material-types-page'>
            <div className='material-types-header'>
                <h1 className='material-types-title'>Типы материалов</h1>
                <div className='material-types-search'>
                    <Form.Control
                        placeholder='Поиск по типам материалов'
                        className='material-types-search-form'
                        onChange={(e) => {
                            const newMaterials = allMaterials.filter(
                                material => material.name?.toLowerCase().includes(e.target.value.toLowerCase()) || 
                                material.amount_type?.toLowerCase().includes(e.target.value.toLowerCase())
                        )
                            setMaterials(newMaterials)
                        }}
                    >
                    </Form.Control>
                </div>
                <Button buttonType="white-shadowed" className='material-types-button' onClick={() => {setIsAdd(true)}}>
                    <div className="material-types-button-content">
                        <img style={{ marginRight: "10px"}} src="/plus.png" width="20" height="20"></img>
                        Добавить тип материала
                    </div>
                </Button>
            </div>
            <MaterialTypesList materialTypes={materials}/>
            <Dialog header="Новый тип материала" visible={isAdd} className="new-detail-type-form" onHide={() => {
                if (!isAdd) return;
                    setIsAdd(false); }}>
                <NewMaterialTypeForm isChange={isChange} setIsChange={setIsChange} isAdd={isAdd} setIsAdd={setIsAdd}/>
            </Dialog >
        </div>
    )
}

export default MaterialTypesPage