export interface MaterialType {
    ID: number;
    name: string;
    amount_type: string;
}

export const AmountTypes = [
     "м",
	 "шт",
	 "мм",
	 "м2",
]