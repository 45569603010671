// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-by-project label {
    font-weight: 600;
    font-size: larger;
    color: var(--primary-text-color);
    margin-bottom: 20px; 
}`, "",{"version":3,"sources":["webpack://./src/widgets/Lists/TasksByProjectList/TasksByProjectList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,gCAAgC;IAChC,mBAAmB;AACvB","sourcesContent":[".task-by-project label {\n    font-weight: 600;\n    font-size: larger;\n    color: var(--primary-text-color);\n    margin-bottom: 20px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
