import { Box, List, MantineProvider } from '@mantine/core';
import React from 'react';
import { ShortStorage } from '../../../entities/storage';
import { useNavigate } from 'react-router-dom';
import StorageListItem from '../../ListItems/StorageListItem/StorageListItem';

interface StorageListProps {
    storages: ShortStorage[]
}

const StorageList = (props: StorageListProps) => {
    
    const navigate = useNavigate();
    
    return (
        <MantineProvider>
            <Box>
            {props.storages.map((storage: ShortStorage) => (
                <div onClick={(e) => {
                    e.preventDefault();
                    navigate("/storages/" + storage.ID)
                }}>
                    <StorageListItem storage={storage} />
                </div>
            ))}
            </Box>
        </MantineProvider>
    )
}

export default StorageList;