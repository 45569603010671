import React, { useEffect, useState } from 'react';
import { FullTaskResponse, TaskType } from '../../../entities/task';
import { instance } from '../../../api/config';
import { FieldMain } from '../../Field/Fields';
import { Button } from '../../Button/Button';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

interface LogisticsTaskInfoProps {
    task : FullTaskResponse
}

const LogisticsTaskInfo = (props: LogisticsTaskInfoProps) => {

    const [prevTask, setPrevTask] = useState<FullTaskResponse>();
    const [nextTask, setNextTask] = useState<FullTaskResponse>()
    const [parent_task, setParentTask] = useState<FullTaskResponse>()

    const navigate = useNavigate()

    useEffect(() => {
        const getPrevTask = async () => {
            if (!props.task.task.prev_task_id) {
                return
            }
            const resp = await instance.get("/api/tasks", {
                params: {"ID": props.task.task.prev_task_id}
            })
                
            if (resp.data.type) {
                resp.data.task = resp.data
            }
            setPrevTask(resp.data)
        }
        const getNextTask = async () => {
            if (!props.task.task.next_task_id) {
                return
            }
            const resp = await instance.get("/api/tasks", {
                params: {"ID": props.task.task.next_task_id}
            })
                
            if (resp.data.type) {
                resp.data.task = resp.data
            }
            setNextTask(resp.data)
        }
        const getParentTask = async () => {
            const resp = await instance.get("/api/tasks", {
                params: {"ID": props.task.task.parent_task_id}
            })
                
            if (resp.data.type) {
                resp.data.task = resp.data
            }
            setParentTask(resp.data)
        }
        getPrevTask()
        getNextTask()
        getParentTask()
    }, [props.task])

    const handleLOGFinish = async (e: React.FormEvent) => {
        e.preventDefault()
        const resp = await instance.patch("/api/tasks", {
            ID: props.task.ID,
            type: props.task.task.type,
            status: true
            })
        toast("Задача завершена")
        navigate("/tasks")
    }
    
    const getAddress = (task: FullTaskResponse) => {
        return (
            <>
            {
                (task.task.type === TaskType.Operation)?

                <div style={{ marginLeft: "10px"}}>
                    <h3>Операция:</h3>
                    <h3>- Тип: {task.operation?.type}</h3>
                    <h3>- Адрес: {task.work_place?.address}</h3>
                </div> : <></>
            }
            {
                (task.task.type === TaskType.Storing)?
                <div style={{ marginLeft: "10px"}}>
                    <h3>Склад:</h3>
                    <h3>- Название: {parent_task?.detail_type?.route_list.storage.name}</h3>
                    <h3>- Адрес склада: {parent_task?.detail_type?.route_list.storage.address}</h3>
                </div> : <></>
            }
            </>
        )
    }



    return (
        <FieldMain className="special-info-item">
            <div>
                <h3 >Адрес получения: </h3>
                {(prevTask != undefined) ?
                    getAddress(prevTask) : <></>
                }
            </div>
            <div>
                <h3>Адрес сдачи: </h3>
                {(nextTask != undefined) ?
                    getAddress(nextTask) : <></>
                }
            </div>
            <Button buttonType='white-shadowed' className="back-button end-button" onClick={handleLOGFinish}>
                Завершить задачу
            </Button>
        </FieldMain>
    )
}

export default LogisticsTaskInfo;