import { useLayoutEffect, useState } from "react"


export const useTheme = () =>{
    const [theme, setTheme] = useState(localStorage.getItem('app-theme'))

    useLayoutEffect(() => {
        document.documentElement.setAttribute('data-theme', theme || '')
    }, [theme])
    localStorage.setItem('app-theme', theme || '')
    return {theme, setTheme}
}