import React, { useEffect } from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import NewTaskPage from "../pages/NewTaskPage/NewTaskPage";
import TasksPage from "../pages/TasksPage/TasksPage";
import {RoutesMap} from "./AppRoutes";
import {navItems} from "../schemas/NavigationBar";
import NavigationBar from "../widgets/NavigationBar/NavigationBar";
import {useAuth} from "../context/AuthProvider";
import LoginPage from "../pages/LoginPage/LoginPage";
import ProjectsPage from "../pages/ProjectsPage/ProjectsPage";
import TaskPage from "../pages/TaskPage/TaskPage";
import DetailTypesPage from "../pages/DetailTypesPage/DetailTypesPage";
import NotebookPage from "../pages/NotebookPage/NotebookPage";
import ProjectTasksPage from "../pages/ProjectTasksPage/ProjectTasksPage";
import StorageListPage from "../pages/StorageListPage/StorageListPage";
import NewStorageForm from "../widgets/Forms/NewStorageForm/NewStorageForm";
import DetailTypePage from "../pages/DetailTypePage/DetailTypePage";
import StoragePage from "../pages/StoragePage/StoragePage";
import UserProfilePage from "../pages/UserProfilePage/UserProfilePage";
import WorkstationsPage from "../pages/WorkPlacesPage/WorkPlacesPage";
import MaterialTypesPage from "../pages/MaretialTypesPage/MaterialTypesPage";

const PrivateRoutes: React.FC = () => {
    const { user, token, checkUser } = useAuth()
    const navigate = useNavigate()
    useEffect(() => {
        checkUser()
    }, [token]);

    if (user === null ) {
        return (
            <Route path={RoutesMap.LOGIN} element={<LoginPage />} />
        );
    }
    if (user.role === null) {
        return (
            <Route path={RoutesMap.LOGIN} element={<LoginPage />} />
        );
    }

    return (
        <NavigationBar navItems={navItems[user?.role]} user={user} >
            <Routes>
                <Route path={RoutesMap.NEW_TASK} element={<NewTaskPage />}/>
                <Route path={RoutesMap.TASKS} element={<TasksPage />} />
                <Route path={RoutesMap.DEPARTMENTS} element={<ProjectsPage />} />
                <Route path="/tasks/:id" element={<TaskPage />} />
                <Route path="/projects/:id" element={<ProjectTasksPage/>}/>
            </Routes>
            <Routes>
                <Route path="/storages" element={<StorageListPage/>}/>
                <Route path="/storages/:id" element={<StoragePage/>}></Route>
            </Routes>
            <Routes>
                <Route path={"/details"} element={<DetailTypesPage />}/>
                <Route path={"/details/:id"} element={<DetailTypePage/>}/>
                <Route path={"/materials"} element={<MaterialTypesPage/>}/>
            </Routes>
            <Routes>
                <Route path={"/notebook"} element={<NotebookPage />}/>
            </Routes>
            <Routes>
                <Route path={"/profile"} element={<UserProfilePage/>}/>
            </Routes>
            <Routes>
                <Route path={"/workstations"} element={<WorkstationsPage/>}/>
            </Routes>
            <></>
        </NavigationBar>
    );
};

export default PrivateRoutes