import styled from "styled-components";
export const FieldRoot = styled.div`
    box-shadow: 0 0.03em 0.15em  #757575;
    background-color: var(--field-root-background-color);
    border-radius: 8px;
`
;

export const FieldMain = styled(FieldRoot)`
    background-color: var(--field-main-background-color);
    border-radius: 8px;

`

export const FieldGeneral = styled(FieldRoot)`
    background-color: var(--field-general-background-color);
`