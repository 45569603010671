import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import {Project} from "../../../entities/project";
import {User} from "../../../entities/user";
import "./GeneralTaskInfo.css"
import '../Task.css'
import { FieldMain } from "../../Field/Fields";
import { S3_URL } from "../../../entities/file";
import { Dialog } from "primereact/dialog";
import { Roles } from "../../../schemas/Roles";
import { Button } from "../../Button/Button";

interface GeneralTaskInfoProps {
    project: Project,
    responsible: User,
    executor: User,
    // progress: number,
    // setProgress: React.Dispatch<React.SetStateAction<number>>
    desciption: string
}
const GeneralTaskInfo: React.FC<GeneralTaskInfoProps> = (props: GeneralTaskInfoProps) => {
    const [className, setClassName] = useState(false);
    const [isContact, setIsContact] = useState(false);
    const toggleBold = () => {
        setClassName(!className);
    }
    return (
        <>
        <div>
            <FieldMain className="action-hover-disable-field">
                <div className="general-info-container">
                    <div className="project-info">
                        <button className="info-button" onClick={toggleBold}>
                            <div>
                                <img src="/info.png" alt="info" width='30' height='30'/>
                            </div>
                        </button>

                        <div className="sub-project-info">
                            <h4>Проект:&#160;</h4>
                            <h3 className={className ? "bold" : ""}> {props.project.name}</h3>
                            
                        </div>
                        
                        <div className="description-info">
                            <h4>Описание:&#160;</h4>
                            <h3 className={className ? "bold" : ""}>{props.desciption}</h3>


                        </div> 

                    </div>     
                    <div className="responsible-person-info">
                        <div className="persona-info-task">
                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>   
                                <div className="avatar">
                                <img style={{ borderRadius: "25px" }} src={(props.responsible.avatar?.path) ? `${S3_URL}${props.responsible.avatar.path}`: "/user.png"} alt="avatar" width='50' height='50' />
                                </div>
                                <div>
                                    <h3>{(props.executor.name && props.executor.surname )? `${props.executor.name} ${props.executor.surname}` : props.executor.username}</h3>
                                    <h4>{Roles[props.executor.role || 0]}</h4>
                                </div>
                            </div>
                            <Button buttonType='white-shadowed' className="info-button-chat" onClick={() => {setIsContact(true)}}>Связаться</Button>

                            
                        </div>
                    </div>          
                    
                </div>
            </FieldMain>
            <Dialog header="Информация о пользователе" visible={isContact} onHide={() => {
                if (!isContact) return;
                setIsContact(false);
            }}>
                <div style={{ marginLeft: "20px", marginRight: "20px"}}>
                    <div style={{ display: "flex",alignItems: "center", justifyContent: "center" }}>
                        <div style={{ marginRight: "10px"}}>
                            <img style={{ borderRadius: "25px" }} src={(props.executor.avatar?.path) ? `${S3_URL}${props.executor.avatar.path}`: "/user.png"} alt="avatar" width='100' height='100' />
                        </div>
                        <div style={{ marginLeft: "10px"}}>
                            <h2>{(props.executor.name && props.executor.surname )? `${props.executor.name} ${props.executor.surname}` : props.executor.username}</h2>
                            <h3>{Roles[props.executor.role || 0]}</h3>
                        </div>
                    </div>
                    <div>
                        <hr></hr>
                        <h6>Почта:    {(props.responsible.email) ? props.responsible.email : "Отсутсвует"}</h6>
                        <h6>Телефон:  {(props.responsible.phone) ? props.responsible.phone : "Отсутсвует"}</h6>
                        <h6>Телеграм: {(props.responsible.telegram) ? props.responsible.telegram : "Отсутсвует"}</h6>
                    </div>
                </div>
            </Dialog>
        </div>
        </>
    )
};

export default GeneralTaskInfo;