// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-type-list-item {
    border-radius: 10px;
    margin-bottom: 20px;
}

.detail-type-list-item-content {
    display: flex;
    align-items: center;
    border-radius: 8px;
}

.detail-type-list-item-content:hover{
    box-shadow: 0 0 10px #F65727;
}
.detail-type-list-item-content:active{
    background-color: var(--button-background-color-active);
    box-shadow: 0 0 10px #F65727;
}

.detail-type-list-item-info {
    height: 120px;
    padding: 15px;
}

.detail-type-list-item-info h6 {
    margin-bottom: 0px;
    color: var(--secondary-text-color);
    font-size: medium;
}

.detail-type-list-item-info h3 {
    margin-bottom: 0px;
    color: var(--primary-text-color);
    font-size: x-large;
}

.detail-type-list-item-info hr {
    margin-top:2px;
    margin-bottom: 4px;
    border-top: 2px solid #F65727;
    border-radius: 8px;
    opacity: 100;
    width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ListItems/DetailTypeListItem/DetailTypeListItem.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;AAChC;AACA;IACI,uDAAuD;IACvD,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,kCAAkC;IAClC,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".detail-type-list-item {\n    border-radius: 10px;\n    margin-bottom: 20px;\n}\n\n.detail-type-list-item-content {\n    display: flex;\n    align-items: center;\n    border-radius: 8px;\n}\n\n.detail-type-list-item-content:hover{\n    box-shadow: 0 0 10px #F65727;\n}\n.detail-type-list-item-content:active{\n    background-color: var(--button-background-color-active);\n    box-shadow: 0 0 10px #F65727;\n}\n\n.detail-type-list-item-info {\n    height: 120px;\n    padding: 15px;\n}\n\n.detail-type-list-item-info h6 {\n    margin-bottom: 0px;\n    color: var(--secondary-text-color);\n    font-size: medium;\n}\n\n.detail-type-list-item-info h3 {\n    margin-bottom: 0px;\n    color: var(--primary-text-color);\n    font-size: x-large;\n}\n\n.detail-type-list-item-info hr {\n    margin-top:2px;\n    margin-bottom: 4px;\n    border-top: 2px solid #F65727;\n    border-radius: 8px;\n    opacity: 100;\n    width: 120px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
