import { List, MantineProvider } from '@mantine/core';
import React from 'react';
import DetailsCountListItem from '../../ListItems/DetailsCountListItem/DetailsCountListItem';
import { DetailCount } from '../../../entities/detail';
import './DetailsCountList.css'

interface DetailsCountListProps {
    details: DetailCount[]
}
const DetailsCountList = (props: DetailsCountListProps) => {
    return (
        <div className="details-count-list">
            {props.details?.map(detail => (
                (detail.ID)? <DetailsCountListItem detail={detail}/> : <></>
            ))}
        </div>
    ) 
}

export default DetailsCountList;