// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.material-types-page {
    margin-left: 80px;
    margin-right: 80px;
    
}

.material-types-header {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.material-types-title {
    font-size: 30px;
    color: var(--primary-text-color);
    margin-right: 20px !important;
}

.material-types-search {
    width: 50%;
}

.material-types-search-form {
    border-radius: 8px;
    height: 60px;
}

.material-types-search-form:focus {
    border: 2px solid #F65727;
    box-shadow: 0 0 0 0 
}

.material-types-button {
    height: 60px !important;
    margin-left: 20px;
    width: max-content;
}

.material-types-button-content {
    display: flex;
    align-content: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/MaretialTypesPage/MaterialTypesPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;;AAEtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gCAAgC;IAChC,6BAA6B;AACjC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB;AACJ;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,uBAAuB;AAC3B","sourcesContent":[".material-types-page {\n    margin-left: 80px;\n    margin-right: 80px;\n    \n}\n\n.material-types-header {\n    display: flex; \n    justify-content: space-between;\n    align-items: center;\n    margin-top: 40px;\n    margin-bottom: 40px;\n}\n\n.material-types-title {\n    font-size: 30px;\n    color: var(--primary-text-color);\n    margin-right: 20px !important;\n}\n\n.material-types-search {\n    width: 50%;\n}\n\n.material-types-search-form {\n    border-radius: 8px;\n    height: 60px;\n}\n\n.material-types-search-form:focus {\n    border: 2px solid #F65727;\n    box-shadow: 0 0 0 0 \n}\n\n.material-types-button {\n    height: 60px !important;\n    margin-left: 20px;\n    width: max-content;\n}\n\n.material-types-button-content {\n    display: flex;\n    align-content: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
