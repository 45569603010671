import React from 'react';

import Notebook from '../../widgets/Notebook/Notebook';

const NotebookPage = () => {

    return (
        <div>
            <Notebook />
        </div>
    );
};

export default NotebookPage;