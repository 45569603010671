import {User} from "./user";


export interface notebook {
    ID: number | null
    title: string | null
    text: string | null
    user_id: number | null
}
export const NewNotebook = () => {
    return {
        ID: null,
        title: null,
        text: null,
        user_id: null
    }
}