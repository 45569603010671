import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import {instance} from "../../../../api/config";
import './NewManufacturingForm.css'
import {DetailType, DetailTypeByID, getDetailTypeByID} from "../../../../entities/detail_type";
import { WorkPlace } from "../../../../entities/workplaces";
import { OperationWorkplace } from "../../../../entities/task";
import Switch from 'react-switch'
interface NewManufacturingFormProps {
    detailType: DetailType,
    setDetailType: (newDetailType: DetailType) => void;
    quantity: number,
    setQuantity: (newQuantity: number) => void;
    operationWorkplaces: OperationWorkplace[]
    setOperationWorkplaces: (newOperationWorkplaces: OperationWorkplace[]) => void;
    hasQualityControl: boolean
    setHasQualityControl: (newHasQualityControl: boolean) => void;

}
export const NewManufacturing: React.FC<NewManufacturingFormProps> = (props) => {

    const [detailTypes, setDetailTypes] = useState<DetailTypeByID>({});
    const [workplaces, setWorkplaces] = useState<WorkPlace[]>([]);
    useEffect(() => {
        const getDetails = async () => {
            const resp = await instance.get("/api/detailTypes/all")
            if (resp?.data) {
                setDetailTypes(getDetailTypeByID(resp.data))
            } else {
                setDetailTypes({})
            }
        }
        const getWorkplaces = async () => {
            const resp = await instance.get("/api/workPlaces/all")
            setWorkplaces(resp.data)
        }
        getDetails()
        getWorkplaces()
    }, []);
    return (
        <div>
            <div style={{ display: "flex"}}>
                <div style={{ width:"60%", marginRight: "10px"}} className="manufacturing-form mb-3">
                    <Form.Select aria-label="Detail"
                                 id="taskDetail"
                                 className="form-control add-mf-task-input-form"
                                 value={props.detailType?.ID || ""}
                                 onChange={(e) => {
                                     props.setDetailType(detailTypes[parseInt(e.target.value)])
                                 }}
                                 required
                    >
                        <option value="" disabled>Выберите деталь</option>
                        {Object.values(detailTypes).map((detailType) => (
                            <option key={detailType.ID} value={detailType?.ID || 0}>
                                {detailType.name}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <div className="manufacturing-form mb-3">
                    <Form.Control aria-label="Количество"
                        id="Quantity"
                        className="form-control add-mf-task-input-form"
                        value={props?.quantity || ""}
                        onChange={(e) => props.setQuantity(parseInt(e.target.value))}
                        required
                        placeholder="Введите количество"
                    />
                </div>
            </div>
            <div style={{ display: "flex", width:"100%", alignItems: "center"}} className="manufacturing-form mb-3">
                    <div style={{ width:"60%", marginRight: "10px"}}>
                    <Form.Control
                        id="Quantity"
                        className="form-control add-mf-task-input-form"
                        required
                        disabled={(props.detailType.ID) ? false : true}
                        placeholder="Материал"
                    />
                    </div>
                    <div style={{ marginRight: "10px", marginBottom: "7px"}}>
                        <Switch uncheckedIcon={false} checkedIcon={false} onColor="#F65727" offColor="#100f12" checked={props.hasQualityControl} onChange={() => {props.setHasQualityControl(!props.hasQualityControl)}}/>
                    </div>
                    <h6>Контроль качества</h6>
            </div>
            <div style={{ marginBottom: "45px" }}>
                <h1>Заполните рабочие места для операций</h1>
            </div>
            <div style={{ display: "flex"}}>
                {
                    props.detailType.route_list.operations.map((operation) => (
                        <div style={{ width: "18%", marginRight: "2%"}}>
                            <h6>{operation.type}</h6>
                            <Form.Select
                                className="form-control add-mf-task-input-form"
                                required
                                defaultValue="0"
                                onChange={(e) => { 
                                    const newOW = props.operationWorkplaces.filter(ow => ow.operation_id != operation.ID)
                                    newOW.push({
                                        operation_id: operation.ID,
                                        work_place_id: parseInt(e.target.value)
                                    })
                                    props.setOperationWorkplaces(newOW)
                                }}
                            >
                            <option value="0" disabled>Выберите рабочее место</option>
                            {workplaces.map((workplace) => {
                                if (workplace.operation_types.filter(op => op.operation_type === operation.type).length)
                                return (
                                <option key={workplace.ID} value={workplace?.ID || 0}>
                                    ({workplace.address}) {workplace.name}
                                </option>
                                )
                            })}
                            </Form.Select>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}