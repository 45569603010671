import React from 'react';
import { DetailType } from '../../../entities/detail_type';
import DetailTypeListItem from '../../ListItems/DetailTypeListItem/DetailTypeListItem';

interface DetailTypesListProps {
    detailTypes: DetailType[]
}
const DetailTypesList = (props: DetailTypesListProps) => {
    return (
        <div>
        {
            props.detailTypes.map((detailType: DetailType) => (
                <DetailTypeListItem
                    detailType={detailType}
                />
            ))
        }
        </div>
    )
}

export default DetailTypesList