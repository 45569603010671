// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-storage-form-container p {
    color: var(--primary-text-color);
    margin-bottom: 5px;
}

.new-storage-input-form {
    border: 2px solid #F65727;
    border-radius: 8px;
    margin-bottom: 20px;
}

.new-storage-buttons {
    display: flex;
    justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/Forms/NewStorageForm/NewStorageForm.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".new-storage-form-container p {\n    color: var(--primary-text-color);\n    margin-bottom: 5px;\n}\n\n.new-storage-input-form {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    margin-bottom: 20px;\n}\n\n.new-storage-buttons {\n    display: flex;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
