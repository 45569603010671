import React, { useState } from 'react';

interface EditableTextProps {
  initialValue?: string;
  placeholder?: string;
  className?: string;
}

export const EditableText = ({ 
  initialValue = '', 
  placeholder = '', 
  className = ''
}: EditableTextProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState(!initialValue);
  const [text, setText] = useState(initialValue);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    if (text.trim()) {
      setIsEditing(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && text.trim()) {
      setIsEditing(false);
    }
  };

  return isEditing ? (
    <input
      type="text"
      value={text}
      onChange={(e) => setText(e.target.value)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      className={`${className}`}
    />
  ) : (
    <div
      onDoubleClick={handleDoubleClick}
      className={`${className}`}
    >
      {text}
    </div>
  );
}