import React, { useState } from 'react';
import {useAuth} from "../../context/AuthProvider";
import NewTaskForm from "../../widgets/Forms/NewTaskForm/NewTaskForm";
import { FieldGeneral, FieldMain } from '../../components/Field/Fields';

const CreateTask = () => {
    const {user} = useAuth()


    if (user === null) {
        return (<div>Не авторизован</div>)
    }
    return (
        <div>
            <FieldMain style={{ marginLeft: "20px", marginRight: "20px", marginTop: "20px" }}>
                <NewTaskForm/>
            </FieldMain>
        </div>
    );
};

export default CreateTask;