import React from 'react';
import { Project } from '../../../entities/project';
import ProjectListItem from '../../ListItems/ProjectListItem/ProjectListItem';

interface ProjectsListProps {
    projects: Project[]
    ID: number
}

const ProjectsList = (props: ProjectsListProps) => {
    return (
            <div>
                {Object.values(props.projects).map((project) => (
                    <ProjectListItem key={project.ID} project={project} departmentID={props.ID}/>
                ))}
            </div>
    )
}

export default ProjectsList;