import React from 'react';
import { Task, TaskType } from '../../../entities/task';
import TaskListItem from '../../ListItems/TaskListItem/TaskListItem';

interface TasksListProps {
    tasks: Task[]
}

const TasksList = (props: TasksListProps) => {
    return (
        <div>
            {
                props.tasks?.map(task => {
                    if (task.visible || task.type === TaskType.QualityControl) {
                        return <TaskListItem
                            ID={task.ID}
                            key={task.ID}
                            type={task.type}
                            name={task.name}
                            date={task.finish_date}
                            progress={task.progress}
                        />   
                        }
                        return <></>
                    })
            }
        </div>
    )
}

export default TasksList;