// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tasks-timeline {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between !important;
    width: 100%;
}

.tasks-timeline:hover {
    box-shadow: none !important;
}

.tasks-timeline:active {
    background-color: var(--field-main-background-color) !important;
    box-shadow: none !important;
}

.tasks-timeline-item {
    padding: 10px;
    align-items: center;
    border-radius: 8px;
    background-color: white !important;
    margin-right: 10px;
    margin-bottom: 5px;
}

.tasks-timeline-item-ready {
    background-color: #FFB39B !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Task/ChildTasksInfo/ChildTasksInfo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,yCAAyC;IACzC,WAAW;AACf;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,+DAA+D;IAC/D,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,kCAAkC;IAClC,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".tasks-timeline {\n    display: flex;\n    flex-wrap: nowrap;\n    justify-content: space-between !important;\n    width: 100%;\n}\n\n.tasks-timeline:hover {\n    box-shadow: none !important;\n}\n\n.tasks-timeline:active {\n    background-color: var(--field-main-background-color) !important;\n    box-shadow: none !important;\n}\n\n.tasks-timeline-item {\n    padding: 10px;\n    align-items: center;\n    border-radius: 8px;\n    background-color: white !important;\n    margin-right: 10px;\n    margin-bottom: 5px;\n}\n\n.tasks-timeline-item-ready {\n    background-color: #FFB39B !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
