// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.material-list-item {
    width: 250px;
    padding: 20px;
    height: 200px;
    margin-right: 40px;
    margin-bottom: 20px;
}
.material-list-item:hover {
box-shadow: 0 0.03em 0.15em  #757575 !important;
}

.material-list-item:active{
    background-color: var(--field-main-background-color) !important;
    box-shadow: 0 0.03em 0.15em  #757575 !important;
}

.material-list-item h3 {
    font-size: x-large;
}

.material-list-item h6 {
    color: var(--secondary-text-color);
    margin-bottom: 10px;
}

.material-list-item p {
    margin-bottom: 3px;
    margin-right: 5px;
}

.material-list-item hr {
    margin-top:2px;
    margin-bottom: 4px;
    border-top: 2px solid #F65727;
    border-radius: 8px;
    opacity: 100;
    width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ListItems/MaterialTypesListItem/MaterialTypesListItem.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,mBAAmB;AACvB;AACA;AACA,+CAA+C;AAC/C;;AAEA;IACI,+DAA+D;IAC/D,+CAA+C;AACnD;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kCAAkC;IAClC,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".material-list-item {\n    width: 250px;\n    padding: 20px;\n    height: 200px;\n    margin-right: 40px;\n    margin-bottom: 20px;\n}\n.material-list-item:hover {\nbox-shadow: 0 0.03em 0.15em  #757575 !important;\n}\n\n.material-list-item:active{\n    background-color: var(--field-main-background-color) !important;\n    box-shadow: 0 0.03em 0.15em  #757575 !important;\n}\n\n.material-list-item h3 {\n    font-size: x-large;\n}\n\n.material-list-item h6 {\n    color: var(--secondary-text-color);\n    margin-bottom: 10px;\n}\n\n.material-list-item p {\n    margin-bottom: 3px;\n    margin-right: 5px;\n}\n\n.material-list-item hr {\n    margin-top:2px;\n    margin-bottom: 4px;\n    border-top: 2px solid #F65727;\n    border-radius: 8px;\n    opacity: 100;\n    width: 120px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
