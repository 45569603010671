import React from 'react';
import { FieldMain } from '../../../components/Field/Fields';
import { Material } from '../../../entities/material';
import { Tag } from '../../../components/Tag/Tag';

interface MaterialListItemProps {
    material: Material
}
const MaterialListItem = (props: MaterialListItemProps) => {
    return (
        <FieldMain className="detail-list-item" >
            <div className='detail-content-info'>
                <h2>{props.material.material_type?.name}</h2>
                <h6>{props.material?.gost}</h6>
                <hr></hr>
                <h6>Размер: {props.material.size}</h6>
                <h6>Количество: {props.material.amount}</h6>
                <h6>Поставщик: {props.material.supplier}</h6>
                <div className='detail-list-items-status'>
                    <h6>Статус: </h6>
                    <Tag>{props.material.status}</Tag>
                </div>
            </div>
        </FieldMain>
    )}

export default MaterialListItem;