// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task {
    margin-left: 80px;
    margin-right: 80px;
}

.task-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
    margin-top: 40px;
    color: var(--primary-text-color);
}

.task-header-search {
    width: 60%;
}

.task-header-search-form{
    height: 60px !important;
}

.task-header-search-form:focus {
    border: 2px solid #F65727;
    box-shadow: none;
}

.task-header-search input{
    height: 40px;
    position: relative;
    margin-left: 20px;
    border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/TasksPage/TasksPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".task {\n    margin-left: 80px;\n    margin-right: 80px;\n}\n\n.task-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: 40px;\n    margin-bottom: 20px;\n    margin-top: 40px;\n    color: var(--primary-text-color);\n}\n\n.task-header-search {\n    width: 60%;\n}\n\n.task-header-search-form{\n    height: 60px !important;\n}\n\n.task-header-search-form:focus {\n    border: 2px solid #F65727;\n    box-shadow: none;\n}\n\n.task-header-search input{\n    height: 40px;\n    position: relative;\n    margin-left: 20px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
