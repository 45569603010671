import {Routes, Route, useNavigate, Navigate} from "react-router-dom";
import LoginPage from "../pages/LoginPage/LoginPage";
import React from "react";
import RequireAuth from "../widgets/RequireAuth/RequireAuth";
import PrivateRoutes from "./PrivateRoutes";

export const RoutesMap = {
    ANY: "*",
    HOME: "/",
    LOGIN: "/login",
    TASKS: "/tasks",
    NEW_TASK: "/tasks/new/:prodId/:parentID",
    DEPARTMENTS: "/departments",
    NEW_PROJECT: "/projects/new",
} as const;

const AppRoutes: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Routes>
            <Route path={RoutesMap.LOGIN} element={<LoginPage />} />
            <Route path={RoutesMap.HOME} element={<RequireAuth />}>
                    <Route index element={<Navigate to="/tasks" replace />} />
                    <Route path={RoutesMap.ANY} element={<PrivateRoutes />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes