import React from 'react';
import { getTasksByProject, Task } from '../../../entities/task';
import TasksList from '../TasksList/TasksList';
import './TasksByProjectList.css'
interface TasksByProjectProps {
    tasks: Task[]
}

const TasksByProjectList = (props: TasksByProjectProps) => {
    const tasksByProject = getTasksByProject(props.tasks)
    return (
        <div className='task-by-project'>
            {Object.entries(tasksByProject).map(([projectName, tasks]) => (
                <div key={projectName}>
                    {/* <label className="header" >{projectName}</label> */}
                    <label></label>
                    <TasksList tasks={tasks} />
                </div>
            ))}
        </div>
    )
}

export default TasksByProjectList;