import React from 'react';
import { MaterialType } from '../../../entities/material_type';
import { FieldGeneral, FieldMain } from '../../../components/Field/Fields';
import './MaterialTypesListItem.css'

interface MaterialTypesListItemProps {
    materialType: MaterialType
}
const MaterialTypesListItem = (props: MaterialTypesListItemProps) => {
    return (
        <FieldMain className='material-list-item'>
            <h3>{props.materialType.name}</h3>
            <hr></hr>
            <h6>Единица измерения: {props.materialType.amount_type}</h6>
        </FieldMain>
    );
}

export default MaterialTypesListItem;