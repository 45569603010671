// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.department-list-item-error-text {
    color: var(--primary-text-color);
    text-align: center;
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ListItems/DepartmentListItem/DepartmentListItem.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".department-list-item-error-text {\n    color: var(--primary-text-color);\n    text-align: center;\n    margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
