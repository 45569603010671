import React from "react";
import Form from "react-bootstrap/Form";
import { StorageTypes } from "../../../entities/storage";
import {useNavigate} from "react-router-dom";
import { instance } from "../../../api/config";
import {toast} from "react-hot-toast";
import './NewStorageForm.css'
import { Button } from "../../../components/Button/Button";


interface NewStorageFormProps {
    setIsAdd: (isAdd: boolean) => void;
}

const NewStorageForm = (props: NewStorageFormProps) => {

    const navigate = useNavigate();

    const [name, setName] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [storageType, setStorageType] = React.useState<string>("");

    const handleCreateStorage = async (e: React.FormEvent) => {
        e.preventDefault();
        const resp = await instance.post("/api/storage", {
            "name": name,
            "address": address,
            "storage_type": storageType,
        })
        setName("")
        setAddress("")
        setStorageType("")
        navigate("/storages")
        toast("Склад успешно создан!")
        props.setIsAdd(false)
    }
    return (
        <div className="">
            <div className="mb-3 new-storage-form-container">
                <p>Тип склада</p>
                <Form.Select aria-label="Task Type"
                             id="taskType"
                             className="form-control new-storage-input-form"
                             value={storageType}
                             onChange={(e) => setStorageType(e.target.value)}
                             required
                >
                    <option value="" disabled>Выберите тип склада</option>
                    {Object.values(StorageTypes).map((storageType) => (
                        <option key={storageType} value={storageType}>{storageType}</option>
                    ))}
                </Form.Select>
            </div>
            <div className="mb-3 new-storage-form-container">
                <p>Название склада</p>
                <Form.Control aria-label="Finish Date"
                              id="taskFinishDate"
                              className="form-control new-storage-input-form"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                />
            </div>
            <div className="mb-3 new-storage-form-container">
                <p>Адрес склада</p>
                <Form.Control aria-label="Finish Date"
                              id="taskFinishDate"
                              className="form-control new-storage-input-form"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              required
                />
            </div>
            <div className="new-storage-buttons">
                <Button buttonType="white-shadowed" className="w-50" style={{ marginRight: "10px"}} onClick={handleCreateStorage}>Создать склад</Button>
                <Button buttonType="white-shadowed" className="w-50" onClick={(e) => {
                    e.preventDefault()
                    props.setIsAdd(false)
                }}>Отмена</Button>
            </div>
        </div>
    )
}

export default NewStorageForm;