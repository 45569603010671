import React from 'react';
import './DetailsCountListItem.css'
import { DetailCount } from '../../../entities/detail';
import { FieldMain } from '../../../components/Field/Fields';
import { Tag } from '../../../components/Tag/Tag';

interface DetailCountListProps {
    detail: DetailCount
}
const DetailsCountListItem = (props: DetailCountListProps) => {
    return (
        <FieldMain className="detail-list-item" >
                <div className='detail-content-info'>
                    <h2>{props.detail.detailType?.name}</h2>
                    <h6>{props.detail.detailType?.number}</h6>
                    <hr></hr>
                    
                    <h6>Количество: {props.detail.count}</h6>
                    <div className='detail-list-items-status'>
                        <h6>Статус: </h6>
                        <Tag>{props.detail.status}</Tag>
                    </div>
            </div>
        </FieldMain>
    )
}

export default DetailsCountListItem;