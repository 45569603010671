import React from 'react';
import { MaterialType } from '../../../entities/material_type';
import MaterialTypesListItem from '../../ListItems/MaterialTypesListItem/MaterialTypesListItem';
import './MaterialTypesList.css'
interface MaterialTypesListProps {
    materialTypes: MaterialType[]
}
const MaterialTypesList = (props: MaterialTypesListProps) => {
    return (
        <div className='material-types-list '>
            {props.materialTypes.map((materialType: MaterialType) => (
                <MaterialTypesListItem
                    materialType={materialType}
                />
            ))}
        </div>
    );
}

export default MaterialTypesList;