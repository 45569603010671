export interface Operation {
    ID: number | null,
    type: string,
    address: string,
    number: string,
    status: boolean
}

export enum OperationType {
    Blanking = "Заготовительная",
    Turning       = "Токарная",
    Milling       = "Фрезерная",
    Bending       = "Гибочная",
    Welding       = "Сварочная",
    Drilling      = "Сверление",
    Benching      = "Слесарная",
    Requirements  = "Требования к детали",
}

interface OperationTypeOption {
    value: number,
    label: string,
}

export const OperationTypes: OperationTypeOption[] = [
    { value: 0, label: "Заготовительная"},
    { value: 1, label: "Токарная"},
    { value: 2, label: "Фрезерная"},
    { value: 3, label: "Гибочная"},
    { value: 4, label: "Сварочная"},
    { value: 5, label: "Сверление"},
    { value: 6, label: "Слесарная"},
    { value: 7, label: "Требования к детали"},
]