import axios from "axios";

const BaseURL = process.env.REACT_APP_BACKEND_BASE_URL || "https://backend.2p.imzrb.ru";

export const instance = axios.create({
    withCredentials: true,
    baseURL: BaseURL,
});

instance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
        return config
    }
);

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            window.location.href = '/login';
        }
    });
