import React from 'react';
import ProjectListItem from '../ProjectListItem/ProjectListItem';
import './DepartmentListItem.css'
import { Project } from '../../../entities/project';
import ProjectsList from '../../Lists/ProjectsList/ProjectsList';

interface DepartmentProps {
    ID: number
    name: string
    projects: Project[] | null
}

const DepartmentListItem: React.FC<DepartmentProps> = (props) => {
    if (props.projects === null || props.projects.length === 0) {
        return (
            <div className='department-list-item-error-text'>На данный момент проектов нет.</div>
        );
    } else {
        return (
            <div>
                <ProjectsList projects={props.projects} ID={props.ID}/>
            </div>
        );
    }
};

export default DepartmentListItem;