import React, {useEffect, useState} from 'react';
import {useAuth} from "../../context/AuthProvider";
import {instance} from "../../api/config";
import { Task} from "../../entities/task";
import {  Form } from 'react-bootstrap';
import TasksByProjectList from '../../widgets/Lists/TasksByProjectList/TasksByProjectList';
import DayMonthYear from '../../widgets/DayMonthYear/DayMonthYear';
import './TasksPage.css';

const TasksPage = () => {
    const { user }= useAuth()
    const [tasks, setTasks] = useState<Task[] | null>(null);
    const [allTasks, setAllTasks] = useState<Task[] | null>(null);

    useEffect(() => {
        const getUserTasks = async () => {
            if (user?.role == 1 || user?.role == 2 || user?.role == 3 || user?.role == 4) {
                const resp = await instance.get("/api/users/current")
                setTasks(resp.data.tasks)
                setAllTasks(resp.data.tasks)
            } else {
                const resp = await instance.get("/api/users/current")
                setTasks(resp.data.executor_tasks)
                setAllTasks(resp.data.executor_tasks)
            }
        }
        getUserTasks()
    }, [user]);

    if (user === null) {
        return <div>Не авторизован</div>;
    }

    
    return (
        <div className='task' >
            <div className='task-header'>
                <h1>Задачи</h1>
                <div className='task-header-search'>
                    <Form.Control
                        className="form-control task-header-search-form"
                        placeholder='Поиск по задачам'
                        onChange={(e) => {
                            const newTasks = allTasks?.filter(task => task.name.toLowerCase().includes(e.target.value.toLowerCase()) || task.ID === parseInt(e.target.value))
                            setTasks(newTasks || null)
                        }}
                    >
                    </Form.Control>
                </div>
                <DayMonthYear tasks={tasks || []} setTasks={setTasks} allTasks={allTasks || []}/>
            </div>
            <TasksByProjectList tasks={tasks || []} />
        </div>
    );
};

export default TasksPage;