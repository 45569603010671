import React, { useEffect, useState } from 'react';
import { instance } from '../../api/config';
import { Form } from 'react-bootstrap';
import { Button } from '../../components/Button/Button';
import {Dialog} from "primereact/dialog";
import './WorkPlacesPage.css'
import WorkPlacesList from '../../widgets/Lists/WorkPlacesList/WorkPlacesList';
import NewWorkPlaceForm from '../../widgets/Forms/NewWorkPlaceForm/NewWorkPlaceForm';
import { WorkPlace } from '../../entities/workplaces';

const WorkPlacesPage = () => {

    const [workPlaces, setWorkPlaces] = useState<WorkPlace[]>([])
    const [allWorkPlaces, setAllWorkPlaces] = useState<WorkPlace[]>([])
    const [addFormActivated, setAddFormActivated] = useState(false)



    useEffect(() => {
        const GetWorkPlaces = async () => {
            const response = await instance.get('/api/workPlaces/all')
            setWorkPlaces(response.data)
            setAllWorkPlaces(response.data)
        }
        
        GetWorkPlaces()
    }, [addFormActivated])

    const hasOperationType = (workplace: WorkPlace, type: string) : boolean => {
        let counter: number = 0;
        workplace.operation_types.forEach(operation => {
            if (operation.operation_type.toLowerCase().includes(type)) {
                counter++
            }
        })
        if (counter > 0) {
            return true
        }
        return false
        
    }

    return (
        <div className='workplaces'>
            <div className='workplaces-header'>
                <h1 style={{fontSize:'30px'}}>Рабочие места</h1>
                <div className='workplaces-header-search'>
                    <Form.Control
                        className='form-control workplaces-header-search-form'
                        placeholder='Поиск по рабочим местам'
                        onChange={(e) => {
                            const newWorkplaces = allWorkPlaces?.filter(workplace => workplace.name?.toLowerCase().includes(e.target.value.toLowerCase()) || workplace.address?.toLowerCase().includes(e.target.value.toLowerCase()) || hasOperationType(workplace ,e.target.value.toLowerCase()))
                            setWorkPlaces(newWorkplaces || null)
                        }}
                    >
                    </Form.Control>
                </div>
                <Button style={{padding:'0', paddingLeft:'5px', paddingRight:'5px'}}
                    buttonType='white-shadowed'
                    onClick ={(e) => {
                        e.preventDefault();
                        setAddFormActivated(true)
                        console.log(addFormActivated);
                    }}
                >
                    <div style={{display:'flex', alignItems:'center'}}>
                        <img style={{marginTop:'5px', marginRight:'5px', marginBottom:'5px'}} src="/plus.png" width="30px" height="30px"></img>
                        <label>Новое рабочее место</label>
                    </div>
                </Button>
            </div>
            <div className='workplaces-page-list'>
                <WorkPlacesList workplaces={workPlaces || []}/>
            </div>
            <Dialog header="Новое рабочее место" visible={addFormActivated} className='new-workplace' onHide={() => {
                if (!addFormActivated) return;
                setAddFormActivated(false);
            }}>
                <NewWorkPlaceForm setAddFormActivated={setAddFormActivated} />
            </Dialog>
            
        </div>
    )
}

export default WorkPlacesPage;