import React, {useEffect, useState} from 'react';
import {NavItem} from "../../schemas/NavigationBar";
import {User} from "../../entities/user";
import {Roles} from "../../schemas/Roles";
import {useAuth} from "../../context/AuthProvider";
import {useNavigate} from "react-router-dom";
import { useTheme } from '../../hooks/UseTheme';
import {Button, ButtonDropdown} from "../../components/Button/Button";
import { Dropdown } from '../../components/Dropdown/Dropdown';
import './NavigationBar.css'
import { instance } from '../../api/config';
import { WorkPlace } from '../../entities/workplaces';
import { S3_URL } from '../../entities/file';

interface NavigationBarProps {
    navItems: NavItem[];
    user: User;
    children: React.ReactNode;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ navItems, user, children }) => {
    const {logout, } = useAuth()

    const [currentWorkplace, setCurrentWorkplace] = useState<WorkPlace>()

    const navigate = useNavigate();
    const handleLogout = async () => {
        logout()
    };

    useEffect(() => {
        const getCurrentWorkplace = async () => {
            const resp = await instance.get("/api/workPlaces", { params: {
                "ID": user.current_work_place_id
                }})
            setCurrentWorkplace(resp.data)
        }
        if (user.current_work_place_id !== 0) {
            getCurrentWorkplace()
        }
    }, [])
    
    if (user.role === null ) {
        navigate("/login")
        return (
            <></>
        )
    }
    return (
        <div>
            <ul className="nav nav-pills flex-column flex-sm-row">
                <li className="nav-item-img" key="0">
                    <a className="text-sm-center" href='https://www.imzrb.ru/'>
                        <img src='/brand.svg' alt='IMZboxlogo' width="40" height="40"/>
                    </a>
                </li>
                <li className="nav-item" key="1">
                    <div>
                        <Button
                            id={`tasks-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#tasks-tab-pane`}
                            style={{ backgroundColor: window.location.pathname === "/tasks" ? "var(--button-background-color-active)" : "var(--button-background-color)" }}
                            type="button"
                            role="tab"
                            aria-controls={`tasks-tab-pane`}
                            aria-selected="true"
                            onClick={() => {
                                navigate(`/tasks`);
                            } } >
                            <div>
                            {(window.location.pathname === "/tasks") ?
                                    <img style={{ marginRight: "10px"}} src="/ok.png" width="20" height="20"></img> : <></>
                            }    
                            Мои задачи
                            </div>
                        </Button>
                    </div>
                    
                </li>
                {navItems.map((item, index) => {
                    if (item.type === 'dropdown') {
                        return (
                            <li className="nav-item" key={index}>
                                <Button
                                    id={`${item.label}-tab`}
                                    data-bs-toggle="dropdown"
                                    style={{ backgroundColor: window.location.pathname === "/departments" || window.location.pathname === "/storages" ? "var(--button-background-color-active)" : "" }}
                                    type="button"
                                    role="tab"
                                    aria-controls={`${item.label}-tab-pane`}
                                >
                                    <div>                            
                                    {(window.location.pathname === "/departments" || window.location.pathname === "/storages") ?
                                        <img style={{ marginRight: "10px"}} src="/ok.png" width="20" height="20"></img> : <></>
                                    }    
                                    {item.label}
                                    </div>
                                </Button>
                                <Dropdown className='dropdown-menu'>
                                    {item.items?.map((dropdownItem, dropdownIndex) => (
                                        <li key={dropdownIndex}>
                                            <ButtonDropdown className='dropdown-button'
                                                    onClick={() => {
                                                        navigate(dropdownItem.route)
                                                    }}>
                                                    <span className='button-name'>{dropdownItem.label}</span>
                                            </ButtonDropdown>
                                            {(item.items?.length === dropdownIndex + 1) ? <></> : <hr className="dropdown-divider"/>}
                                        </li>
                                    ))}                 
                                </Dropdown>
                            </li>
                        );
                    }
                    return null;
                })}
                <li className="nav-item" key="2">
                    <Button
                        id={`notebook-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#notebook-tab-pane`}
                        style={{ backgroundColor: window.location.pathname === "/notebook" ? "var(--button-background-color-active)" : "var(--button-background-color)" }}
                        type="button"
                        role="tab"
                        aria-controls={`notebook-tab-pane`}
                        aria-selected="true"
                        onClick={(e) => {
                            navigate(`/notebook`)
                        }}
                    >
                        <div>
                            {(window.location.pathname === "/notebook") ?
                                    <img style={{ marginRight: "10px"}} src="/ok.png" width="20" height="20"></img> : <></>
                            }    
                            Блокнот
                        </div>
                    </Button>
                </li>
                {(user.role === 0)?
                    <li className="nav-item">
                        <Button
                            id={`workplaces-tab`}
                            data-bs-toggle="dropdown"
                            
                            type="button"
                            role="tab"
                            aria-controls={`workplaces-tab-pane`}
                        >
                            Рабочие места
                        </Button>
                        <Dropdown className='dropdown-menu'>
                            {user.workplaces?.map((workplace, workplaceIndex) => (
                                <li key={workplaceIndex}>
                                    <ButtonDropdown className='dropdown-button'
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                await instance.patch("/api/workPlaces", {
                                                    ID: workplace.ID,
                                                    current_user_id: user.ID 
                                                } )
                                                setCurrentWorkplace(workplace)
                                            }}
                                            >{workplace.name} ({workplace.address})
                                    </ButtonDropdown>
                                    {(user.workplaces?.length === workplaceIndex + 1) ? <></> : <hr className="dropdown-divider"/>}
                                </li>
                            ))}
                        </Dropdown>
                    </li> 
                    : <></>}
                {(user.role === 1)?
                    <li className="nav-item" key="3">
                        <Button
                            id={`notebook-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#notebook-tab-pane`}
                            style={{ backgroundColor: window.location.pathname === "/details" ? "var(--button-background-color-active)" : "var(--button-background-color)" }}
                            type="button"
                            role="tab"
                            aria-controls={`notebook-tab-pane`}
                            aria-selected="true"
                            onClick={(e) => {
                                navigate(`/details`)
                            }}>
                            <div>
                                {(window.location.pathname === "/details") ?
                                    <img style={{ marginRight: "10px"}} src="/ok.png" width="20" height="20"></img> : <></>
                                }
                                Документация
                            </div>
                        </Button>
                    </li>
                    : <></>}
                {(user.role === 1)?
                    <li className="nav-item" key="4">
                        <Button
                            id={`workstation-tab`}
                            data-bs-toggle="tab"
                            style={{ backgroundColor: window.location.pathname === "/workstations" ? "var(--button-background-color-active)" : "var(--button-background-color)" }}
                            data-bs-target={`#workstation-tab-pane`}
                            type="button"
                            role="tab"
                            aria-controls={`workstation-tab-pane`}
                            aria-selected="true"
                            onClick={(e) => {
                                navigate(`/workstations`)
                            }}>
                            <div>
                            {(window.location.pathname === "/workstations") ?
                                    <img style={{ marginRight: "10px"}} src="/ok.png" width="20" height="20"></img> : <></>
                            }
                            Рабочие места
                            </div>
                        </Button>
                    </li>
                    : <></>}
                {(user.role === 1)?
                    <li className="nav-item" key="4">
                        <Button
                            id={`workstation-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#workstation-tab-pane`}
                            style={{ backgroundColor: window.location.pathname === "/materials" ? "var(--button-background-color-active)" : "var(--button-background-color)" }}
                            type="button"
                            role="tab"
                            aria-controls={`workstation-tab-pane`}
                            aria-selected="true"
                            onClick={(e) => {
                                navigate(`/materials`)
                            }}>
                        <div>
                            {(window.location.pathname === "/materials") ?
                                    <img style={{ marginRight: "10px"}} src="/ok.png" width="20" height="20"></img> : <></>
                            }    
                            Типы материалов
                        </div>
                        </Button>
                    </li>
                    : <></>}
                <ul className="navbar-nav me-auto mb-lg-0"></ul>
                <li className="nav-item">
                    <div className="user-box">
                        <form className="d-flex">
                            <label id="user_label">
                                {(user.name && user.surname) ? `${user.name} ${user.surname}` : user.username}
                                <br/>
                                {Roles[user.role]} {(user.current_work_place_id !== 0) ? `(${currentWorkplace?.address})` : ""}
                            </label>
                            <img src={(user?.avatar?.path) ? `${S3_URL}${user?.avatar?.path}` : '/user.png'} alt='avatar' width='50' height='50' id="avatar_icon" onClick={(e) => navigate(`/profile`)} />
                            <li className="nav-item">
                            </li>
                        </form>

                    </div>
                </li>
            </ul>
            {children}        
        </div>
    );
};

export default NavigationBar;