import React, {useEffect, useState} from "react";
import {instance} from "../../../api/config";
import {useNavigate, useParams} from "react-router-dom";
import {AddTaskRequest, OperationWorkplace, TaskType} from "../../../entities/task";
import {Operation} from "../../../entities/operation";
import {getProjectByID, NewProject, Project, ProjectByID} from "../../../entities/project";
import {getUserByID, NewUser, User, UserByID} from "../../../entities/user";
import Form from 'react-bootstrap/Form';
import {NewManufacturing} from "./NewManufacturingForm/NewManufacturingForm";
import {convertYYYYMMDDToDate} from "../../../common/date";
import './NewTaskForm.css'
import {toast} from "react-hot-toast";
import {DetailType, NewDetailType} from "../../../entities/detail_type";
import NewPurchaseTaskForm from "./NewPurchaseTask/NewPurchaseTask";
import { Button } from "../../../components/Button/Button";
import { Select } from "../../../components/Select/Select";
import { Input } from "../../../components/Input/Input";
import Switch from 'react-switch'
import { Tag } from "../../../components/Tag/Tag";

const NewTaskForm = () => {
    const navigate = useNavigate();
    const { projId, parentId } = useParams();
    
    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [type, setType] = useState(''); // Пример типов задач, можно заменить на ваши типы
    const [finishDate, setFinishDate] = useState('');
    const [operations, setOperations] = useState<Operation[]>([]);
    const [detailType, setDetailType] = useState<DetailType>(NewDetailType());
    const [quantity, setQuantity] = useState(0);
    const [operationWorkplaces, setOperationWorkplaces] = useState<OperationWorkplace[]>([]);
    const [isParentTask, setIsParentTask] = useState(false)
    const [parentTaskID, setParentTaskID] = useState<number | null>(null)
    const [hasQualityControl, setHasQualityControl] = useState(false)

    const [chosenProject, setChosenProject] = useState<Project>(NewProject());
    const [projects, setProjects] = useState<ProjectByID>({});

    const [chosenUser, setChosenUser] = useState<User>(NewUser());
    const [users, setUsers] = useState<UserByID>({});

    const [materialTypeID, setMaterialTypeID] = useState(0)
    const [storageID, setStorageID] = useState(0)
    const [gost, setGost] = useState('')
    const [size, setSize] = useState('')
    const [isInBudget, setIsInBudget] = useState(false)
    const [predictedPrice, setPredictedPrice] = useState(0)
    const [supplyProvider, setSupplyProvider] = useState('')
    const [supplyWeight, setSupplyWeight] = useState(0)
    const [supplyTimeAfterPaymentFrom, setSupplyTimeAfterPaymentFrom] = useState(0)
    const [supplyTimeAfterPaymentTo, setSupplyTimeAfterPaymentTo] = useState(0)
    const [predictedSupplyDate, setPredictedSupplyDate] = useState('')
    const [plannedSupplyDate, setPlannedSupplyDate] = useState('')
    const [supplyDate, setSupplyDate] = useState('')

    useEffect(() => {
        const getProjects = async () => {
            const resp = await instance.get("/api/projects/all")
            setProjects(getProjectByID(resp.data))
        }
        getProjects()
    }, []);

    const onProjectChange = async (project: Project) => {
        const resp = await instance.get("/api/projects", { params: {
            "ID": project.ID
            }})
        setChosenProject(resp.data)
        if (project.users === null) {
            setUsers({})
            return
        }
        setUsers(getUserByID(project.users))
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (type === '') {
            return
        }
        const newTask: AddTaskRequest = {
            project_id: chosenProject?.ID || 0,
            type: type,
            name: name,
            description: description,
            responsible_id: chosenUser?.ID || 0,
            progress: 0,
            operations: operations,
            parent_task_id: parentTaskID,
            start_date:  convertYYYYMMDDToDate(finishDate),
            finish_date: convertYYYYMMDDToDate(finishDate),
            visible: true,
            status: false,
            prev_task_id: null,
            next_task_id: null,

        };

        if (type === "Заготовительная") {
            newTask.name = `Изготовить: ${detailType.name}`;
            newTask.detail_type_id = detailType?.ID || 0
            newTask.quantity = quantity
            newTask.operations_workplaces = operationWorkplaces
            newTask.has_quality_control = hasQualityControl
        }
        if (type === "Закупка") {
            newTask.name = `Закупить: ${gost}`;
            newTask.material_type_id = materialTypeID
            newTask.gost = gost
            newTask.size = size
            newTask.storage_id = storageID
            newTask.quantity = quantity
            newTask.is_in_budget = isInBudget
            newTask.predicted_price = predictedPrice
            newTask.supplier = supplyProvider
            newTask.supply_weight = supplyWeight
            newTask.supply_time_after_payment_from = supplyTimeAfterPaymentFrom
            newTask.supply_time_after_payment_to = supplyTimeAfterPaymentTo
            newTask.predicted_supply_date = convertYYYYMMDDToDate(predictedSupplyDate)
            newTask.planned_supply_date = convertYYYYMMDDToDate(plannedSupplyDate)
            newTask.supply_date = convertYYYYMMDDToDate(finishDate)
        }
        try {
            const response = await instance.post('/api/tasks', newTask);
            setType('')
            setFinishDate('');
            navigate("/tasks")


        } catch (error) {
            console.error('Error creating task:', error);
        }
        toast("Задача успешно создана!")

    };

    return (
        <div style={{ padding: "10px"}} className="sub-menu">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "20px"}}>
                <Button buttonType="white-shadowed" onClick={() => { navigate('/departments')}}>Назад к проектам</Button>
                <h2>Создание задачи</h2>
                <Button buttonType="white-shadowed" onClick={handleSubmit} type="submit">Создать задачу</Button>
            </div>
            <form onSubmit={handleSubmit} className="form-floating">    
                <div className="form-floating-task-info">
                    <div className="sub-button">
                        <div className="mb-3">
                            <Form.Select aria-label="Task Type"
                                         id="taskType"
                                         value={type}
                                         className="form-control add-task-input-form"
                                         onChange={(e) => setType(e.target.value as TaskType)}
                                         required
                            >
                                <option value="" disabled>Выберите тип задачи</option>
                                {Object.values(TaskType).map((taskType) => (
                                    <option key={taskType} value={taskType}>{taskType}</option>
                                ))}
                            </Form.Select>
                        </div>
                        {(type === 'Общая') ?
                            <div>
                                <div className="mb-3">
                                    <Form.Control aria-label="Finish Date"
                                                  id="taskFinishDate"
                                                  value={name || ""}
                                                  maxLength={50}
                                                  className="add-task-input-form"
                                                  onChange={(e) => setName(e.target.value)}
                                                  required
                                                  placeholder="Название задачи"
                                    />
                                </div>
                            </div> : <></>
                        }
                        <div className="mb-3">
                            <Form.Control aria-label="Finish Date"
                                          id="taskFinishDate"
                                          className="form-control add-task-input-form"
                                          type="date"
                                          value={finishDate}
                                          onChange={(e) => setFinishDate(e.target.value)}
                                          required
                            />
                        </div>
                        <div className="mb-3">
                            <Form.Select aria-label="Project"
                                         id="taskProject"
                                         className="form-control add-task-input-form"
                                         defaultValue="0"
                                         onChange={(e) => {
                                             setChosenProject(projects[e.target.value])
                                             onProjectChange(projects[e.target.value])
                                         }
                                         }
                                         required
                            >
                                <option value="0" disabled>Выберите проект</option>
                                {Object.values(projects).map((project) => (
                                    <option key={project.ID} value={project?.ID || 0}>
                                        {project.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'baseline', height: "60px"}}>
                            <div style={{ marginRight: "10px"}}>
                                <Switch uncheckedIcon={false} checkedIcon={false} onColor="#F65727" offColor="#100f12" checked={isParentTask} onChange={() => {
                                    setIsParentTask(!isParentTask)
                                    setParentTaskID(0)
                                }}/>
                            </div>
                            <div style={{ width: "100%"}} className="mb-3">
                                <Form.Select aria-label="User"
                                            id="taskUser"
                                            className="form-control add-task-input-form"
                                            value={parentTaskID || 0}
                                            onChange={(e) => {
                                                setParentTaskID(parseInt(e.target.value))
                                            }}
                                            required
                                            disabled={(isParentTask) ? false : true}
                                >
                                    <option value="0" disabled>Выберите родительскую задачу</option>
                                    {chosenProject?.tasks?.map((task) => (
                                        <option key={task.ID} value={task.ID}>
                                            {task.ID}. {task.name} {(task.responsible.name && task.responsible.surname)? `${task.responsible.name} ${task.responsible.surname}` : task.responsible.username }
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                        <div className="mb-3">
                            <Form.Select aria-label="User"
                                         id="taskUser"
                                         className="form-control add-task-input-form"
                                         value={chosenUser?.ID || ""}
                                         onChange={(e) => {
                                             setChosenUser(users[e.target.value])
                                         }}
                                         required
                            >
                                <option value="" disabled>Выберите пользователя</option>
                                {Object.values(users).map((user) => (
                                    <option key={user.ID} value={user?.ID || 0}>
                                        {user.username}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="sub-button">
                        {(type === 'Заготовительная') ?
                            <NewManufacturing
                                detailType={detailType}
                                setDetailType={setDetailType}
                                quantity={quantity}
                                setQuantity={setQuantity}
                                operationWorkplaces={operationWorkplaces}
                                setOperationWorkplaces={setOperationWorkplaces}
                                hasQualityControl={hasQualityControl}
                                setHasQualityControl={setHasQualityControl}
                            /> : <></>
                        }
                        {(type === 'Закупка') ?
                            <NewPurchaseTaskForm
                                materialTypeID={materialTypeID}
                                setMaterialTypeID={setMaterialTypeID}
                                storageID={storageID}
                                setStorageID={setStorageID}
                                gost={gost}
                                setGost={setGost}
                                quantity={quantity}
                                setQuantity={setQuantity}
                                size={size}
                                setSize={setSize}
                                isInBudget={isInBudget}
                                setIsInBudget={setIsInBudget}
                                predictedPrice={predictedPrice}
                                setPredictedPrice={setPredictedPrice}
                                supplyProvider={supplyProvider}
                                setSupplyProvider={setSupplyProvider}
                                supplyWeight={supplyWeight}
                                setSupplyWeight={setSupplyWeight}
                                supplyTimeAfterPaymentFrom={supplyTimeAfterPaymentFrom}
                                setSupplyTimeAfterPaymentFrom={setSupplyTimeAfterPaymentFrom}
                                supplyTimeAfterPaymentTo={supplyTimeAfterPaymentTo}
                                setSupplyTimeAfterPaymentTo={setSupplyTimeAfterPaymentTo}
                                predictedSupplyDate={predictedSupplyDate}
                                setPredictedSupplyDate={setPredictedSupplyDate}
                                plannedSupplyDate={plannedSupplyDate}
                                setPlannedSupplyDate={setPlannedSupplyDate}
                                supplyDate={supplyDate}
                                setSupplyDate={setSupplyDate}
                        /> : <></>
                    }
                    </div>
                </div>
                <div>
                            <textarea placeholder="Введите описание задачи" name="NewProjectDescription"
                                      rows={6}
                                      className="add-task-input-form"
                                      value={description || ""}
                                      onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                    </div>
            </form>
        </div>
    );
}

export default NewTaskForm;